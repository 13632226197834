import React from 'react';

export default function Privacy() {
    return (
        <div>
            <section>
                <p>
                    Shelly Access Service branded Shelly Access Control is
                    digital service that allows remote access, control and
                    monitoring of Shelly /Shelly branded Devices (“Shelly Access
                    Devices”) and the appliances to which these are attached.
                </p>
                <p>
                    This Privacy Policy shall apply to the processing of
                    personal data, that is or might be performed when using
                    Shelly Access Service accessed by using either Android or
                    iOS mobile application Shelly Access Control or any internet
                    browser at{' '}
                    <a href="https://accesscontrol.shelly.com/">
                        https://accesscontrol.shelly.com/
                    </a>
                    , whereas the Shelly Access Service might be used as free of
                    charge referred to in this document in general as “the
                    Shelly Access Service” / “the Access Service”.
                </p>
                <p>
                    When you interact with the Shelly Access Service, Shelly
                    Europe Ltd. (“We”) are processing Your Personal Data. We
                    have developed this Privacy Notice to provide You with
                    information about what information we collect, why we
                    collect it, and what are your rights under the applicable
                    data protection laws, including the European Union’s General
                    Data Protection Regulation (“GDPR”).
                </p>
            </section>
            <section>
                <h2>Controller</h2>
                <p>
                    The Personal Data related to Shelly Access Service are
                    processed by Shelly Europe Ltd., UIC: 202320104, having its
                    seat and registered address in Europe, Bulgaria, 1407 Sofia,
                    No 103 Cherni Vruh Blvd.
                </p>
            </section>
            <section>
                <h2>Personal Data</h2>
                <p>
                    Personal data means any information relating to an
                    identified or identifiable natural person (‘data subject’);
                    an identifiable natural person is one who can be identified,
                    directly or indirectly, in particular by reference to an
                    identifier such as a name, an identification number,
                    location data, an online identifier or to one or more
                    factors specific to the physical, physiological, genetic,
                    mental, economic, cultural or social identity of that
                    natural person, as that term is defined under the General
                    Data Protection Regulation (GDPR).
                </p>
            </section>
            <section>
                <h2>Data Subject</h2>
                <p>
                    The Data Subjects to whom this Privacy Notice shall apply is
                    any registered user of the Shelly Access Service including
                    under Administrator/Technician or User Account (“the Service
                    User” / “You”).
                </p>
            </section>
            <section>
                <h2>The Data Protection Officer</h2>
                <p>
                    You may address all your requests about the processing of
                    your Personal Data to our Data Protection Officer via e-mail
                    at <a href="mailto:dpo@shelly.com">dpo@shelly.com</a>
                </p>
            </section>
            <section>
                <h2>About The Shelly Access Service</h2>
                <p>
                    Shelly Access Service allows you to monitor and control the
                    access to premises remotely. The Service might be accessed
                    using Your mobile device and/or RFID token, Bluetooth
                    button, computer, laptop or tablet (“Terminal Device(s)”)
                </p>
                The Service is related to the usage of Shelly Access Devices
                which is any smart device branded „Shelly” in all models and
                modifications, designed for detection and report of
                doors/windows opening or closing, that upon adding to an User
                Account allows remote monitoring and control of the access to
                premises.
                <p>
                    The information that we collect, and how that information is
                    used, depends on how you use our Accesss Service and our
                    Shеlly Device(s) and how you manage your privacy controls
                    via the Service settings and the settings of the Terminal
                    Device on which you use it.
                </p>
                <p>
                    For ensuring the access control and security each Shelly
                    Device can be added to only one administrative account
                    (Master Admin) whereas only the account owner of this Admin
                    Account may at his/her discretion share or initiate the
                    sharing of Device access and information with other parties
                    or Users.
                </p>
            </section>
            <section>
                <h2>
                    1. Types of personal data that we process for the provision
                    of the Service:
                </h2>
                <p>
                    <strong>
                        On a contractual basis for the provision of the Service
                        we process data about:
                    </strong>
                </p>
                <p>
                    Your Account, your paired Shelly Device(s) and the Paired
                    Devices on which the Service is used, as well as data
                    derived from the settings of the Shelly Service and/or the
                    settings of your Associate Services or browsers, Your
                    interaction with the Service.
                </p>
                <ol>
                    <li>
                        <strong>1.1 Account Data</strong>
                        <p>We process Account data that:</p>
                        <ul>
                            <li>
                                you have directly provided to us when creating
                                Your Account, interacting with the Service using
                                its features or when contacting our support team
                                directly; or
                            </li>
                            <li>
                                provided by a third party – the Admin Account
                                holder, who is the administrator of the premises
                                the access to which is to be controlled and
                                monitored;{' '}
                            </li>
                            <li>Email address;</li>
                            <li>Password;</li>
                            <li>Phone number;</li>
                            <li>Profile picture;</li>
                            <li>Local network credentials;</li>
                            <li>Time zone settings;</li>
                            <li>Location services;</li>
                            <li>Language settings;</li>
                            <li>
                                Data related to customer support such as
                                e-mails, messages, history of the
                                correspondence, any correspondence content,
                                sender and recipient information;
                            </li>
                        </ul>
                        <strong>Purpose for processing Account Data:</strong>
                        <p>
                            We are using the Account data for the purpose of
                            performing the Service contract in accordance with
                            Art. 6(1)(b) GDPR to:
                        </p>
                        <ul>
                            <li>
                                verify the User’s authentication and
                                identification in order to ensure access control
                                to Your Account and the Shelly Access Device(s);
                            </li>
                            <li>
                                Initiate and provide the Access Service and to
                                ensure its proper functioning;
                            </li>
                            <li>
                                Ensure the security of the User’s Account and
                                the access to the managed Shelly Access
                                Device(s);
                            </li>
                            <li>
                                Process of support rеquests that You might have
                                send to us in reference to the Service and the
                                Shelly Access Devices;
                            </li>
                            <li>
                                Communicate with You in reference to the Access
                                Service and send you Service updates or other
                                relevant Service information (including
                                reminders, confirmations, necessary customer
                                support when requested and other Access Service
                                relevant information);
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>1.2. Device Data and Service Usage</strong>
                        <p>
                            <strong>
                                Device Data and Service Usage processed for
                                provide You with the Service
                            </strong>
                        </p>
                        <p>
                            For providing You with the Access Service We are
                            also processing information about the Shelly Access
                            Devices that are added to the Admin Account and
                            shared with Users of its choice including the
                            information about the Administrator and User’s
                            interaction with the Service, as well as information
                            about any Terminal Devices on which the Service is
                            installed and accessed such as computer, laptop,
                            tablet, mobile device, that is technically necessary
                            for the provision of the Service
                        </p>
                        <p>
                            By adding the Shelly Device to an Admin Account, the
                            Administrator is sharing the information generated
                            and stored by the Access Device (such as set up
                            actions, schedules, modes, timers or other
                            configurations that you have set up on the Access
                            Device). This information is necessary for providing
                            the Service and the functioning of its features. For
                            stop sharing this information the Administrator can
                            always remove the Shelly Access Device from the
                            Service.
                        </p>
                        <p>
                            When the Access Service is installed, accessed or
                            used, including when an Administrator is adding a
                            Shelly Access Device to the Admin Account or is
                            sharing the access to a Shelly Access Device, as
                            well as (if applicable) the Administrator is sharing
                            information from the Shelly Access Service with
                            other applications and services (Associated Services
                            / Integrators) We <b>automatically</b> process:
                        </p>
                        <ul>
                            <li>
                                Shelly Device identifiers such as access point
                                (SSID), Device IP, Device type, decimal ID,
                                Device name and channel, Version of Device
                                Firmware, Bluethoot data (if available), MAC
                                address of the Device, other configuration data;
                            </li>
                            <li>Network and server credentials;</li>
                            <li>
                                Information about associated Services, browsers
                                and Terminal Devices that are used for access to
                                the Service including Terminal Device type and
                                network provider, unique identifiers such as IP
                                address and MAC address, operating system and OS
                                version;
                            </li>
                            <li>
                                Log information such as Service logs, including
                                crash and diagnostic reports, Service launches,
                                taps, clicks, or other information about how the
                                user interacts with the Service;
                            </li>
                            <li>
                                Event logs and event log settings, such as on/of
                                modes, schedules, actions;
                            </li>
                            <li>
                                Other information about the Service usage
                                depending on the Device type and features;
                            </li>
                        </ul>
                        <strong>
                            Purpose for processing Data of Access Devices
                        </strong>
                        <ul>
                            <li>
                                Provision of the Service – to enable the remote
                                monitoring and control of the access to premises
                                and to enable the set up of actions, we need
                                information about the integrated Shelly Access
                                Devices, the Terminal Device on which the
                                Service is in use and their connectivity to each
                                other, to the network and to our system.
                                Further, for some features of the Service such
                                as the scheduling we need to know the
                                geolocation of the Device;
                            </li>
                            <li>Processing of customer support rеquests;</li>
                            <li>
                                Sending of In-App, including push-up, or e-mail
                                system notifications about the Service,
                                including event notifications (when set-up);
                            </li>
                        </ul>
                    </li>
                </ol>
            </section>
            <section>
                <h2>
                    2. On the basis of Your explicit consent, expressed via the
                    Terminal Device settings or by deliberate actions within the
                    Service we may process further Device Data and Service Usage
                    in accordance with Art. 6(1)(a) of GDPR.
                </h2>

                <ol>
                    <p>
                        <strong>
                            2.1. Data derived from the settings of the
                            ShellyAccess Service and/or the settings of your
                            Associate Services, Terminal Devices or browsers;
                        </strong>
                    </p>
                    <ul>
                        <li>
                            Content that You have create and upload in the
                            Service (images, videos);
                        </li>
                        <li>Access events/logs;</li>
                        <li>Device sharing;</li>
                        <li>
                            Interactions history with the Service and Shelly
                            Access Device(s) (user/event logs);
                        </li>
                        <li>
                            Other information of your choice, that you provide
                            through the settings of the Access Service,
                            Associated Service or Terminal Devices;
                        </li>
                        <li>
                            Other information about the way you are using the
                            Shelly Access Device(s);
                        </li>
                    </ul>
                </ol>
                <p>
                    You may control the processing of these data at any time
                    through the settings of the Access Service and the Terminal
                    Device on which you use it. These shall not prejudice the
                    legality of the processing based on your interactions with
                    the Service and settings given before their change.
                </p>
                <p>
                    You can initiate and stop sharing information of a selected
                    Shelly Access Device with third parties and services (when
                    such option is available) at any time by using the features
                    of the Access Service. Please take into account that upon
                    sharing Your Data with third parties and services this
                    Privacy Notice shall not apply to the processing of the
                    shared Data by these parties and services.
                </p>
                <p>
                    <strong>
                        Purpose for processing Data of the Access Devices
                    </strong>
                </p>
                <ul>
                    <li>
                        Provision of the Service – to enable the remote
                        monitoring and control of the access to premises and to
                        enable the set up of access actions, we need information
                        about the integrated Shelly Access Devices, the Terminal
                        Device on which the Service is in use and their
                        connectivity to each other, to the network and to our
                        system;
                    </li>
                    <li>
                        Authentication, verification and control of the access
                        to your Shelly Access Device(s) including enabling and
                        control of access sharing and manging of user accounts;
                    </li>
                    <li>
                        Sending of notifications (including In-App notifications
                        such as push-up or e-mail) based on event log of the
                        Shelly Device;
                    </li>
                    <li>Processing of support rеquests;</li>
                </ul>
                <ol>
                    <li>
                        <strong>
                            {' '}
                            2.2. Further Account and Device Data that we process
                            based on Your explicit consent:
                        </strong>
                    </li>
                    <ul>
                        <li>
                            E-mail Address and Account Data for sending You
                            In-App, push-up and e-mail marketing notifications
                            about Shelly products and Services, including
                            personalized ones if you have opted-in accordingly;
                        </li>
                    </ul>
                </ol>
                <p>
                    You may withdraw your consent at any time through the
                    unsubscribe link at the bottom of the relevant marketing
                    message, as well as through the settings of Your Service
                    Account. Upon withdrawal of the consent, processing of the
                    respective type of personal data shall be terminated for the
                    stated purposes. Withdrawal of consent shall not prejudice
                    the legality of the processing based on consent given before
                    its withdrawal.
                </p>
            </section>
            <section>
                <h2>
                    3. Based on our legitimate interest in accordance with Art.
                    6(1)(f) of GDPR we may process Your personal data, as
                    described here above to:{' '}
                </h2>
                <ol>
                    <li>
                        I. implement and operate our policies and procedures;
                    </li>
                    <li>
                        II. respond to any claims against us and to protect the
                        rights, privacy, property, or safety of Shelly Europe
                        Ltd. (including its affiliates and subsidiaries), our
                        Users, or the public as required or permitted by law;
                    </li>
                    <li>
                        III. enforce legal claims, including investigation of
                        potential violations of the applicable terms of Service;
                    </li>
                    <li>
                        IV. detect, prevent, or otherwise address fraud, abuse,
                        security, or technical issues with the Service;
                    </li>
                    <li>
                        V. prevent hacking, frauds and other non-compliant use
                        of the Service;
                    </li>
                    <li>
                        VI. register, mediate, and resolve possible disputes or
                        irregularities or to enforce our Terms of Use and other
                        policies;
                    </li>
                    <li>
                        VIII. fulfill the terms and conditions of the Service;
                    </li>
                    <li>
                        IX. other legitimate business purposes permitted by
                        applicable law
                    </li>
                    <li>
                        X. anonymized and statistic data about the usage of our
                        Service and the users experience for the purposes of
                        operating, evaluating, and improving the Service and our
                        business;
                    </li>
                    <li>
                        XI. maintain the Access Service to ensure it is working
                        as intended by performing regular monitoring of our
                        system and activity information to identify and fix
                        problems and avoid interruption;
                    </li>
                    <li>
                        XII. improve the Service through analysis of the usage
                        trends and preferences;
                    </li>
                </ol>
            </section>
            <section>
                <h2>
                    4. Based on the law when it is necessary or appropriate, we
                    can process Your Personal in accordance with Art. 6(1)(с) of
                    GDPR to:
                </h2>
                <ol>
                    <li>
                        i. comply with applicable laws and regulations,
                        including in the field of tax and accounting;
                    </li>
                    <li>ii. comply with legal procedures;</li>
                    <li>
                        iii. respond to requests or orders from public,
                        government and juditial authorities;
                    </li>
                </ol>
            </section>
            <section>
                <h2>5. Sharing Information with Third Parties:</h2>
                <p>
                    We may disclose Your Data internally within our business
                    group and to the following entities, but only for the
                    purposes described above. The following categories of
                    Third-Party Service Providers may process your Personal Data
                    as part of our operations:
                </p>
                <ul>
                    <p>
                        <strong>Admin Account Holder</strong> - due to the
                        specifics of the Access Service which is intended to
                        provide control and monitoring of the access to premises
                        owned, used or administrated by the Admin Account
                        Holder, the latter is entitled to add Users to its
                        Admins Account and to determine or limit their access
                        rights. For this reason, the Admin Account Holder has
                        access to all data of or generated by the User Account.
                    </p>
                    <p>
                        <strong>Affiliates</strong> - other companies within the
                        group of Shelly Group PLC to which Shelly Europe Ltd.
                        belongs to carry out business activities on a regular
                        basis;
                    </p>
                    <p>
                        <strong>Integrators</strong> of third-party products or
                        services to which you can connect the Service to control
                        those products or services. These business partners
                        control and manage Your personal information only upon
                        your explicit consent to share Your Data with them which
                        you can withdraw anytime. These business partners
                        process Your Data in compliance with their own privacy
                        policies and rules and therefore before deciding to
                        share Your Access to the Service or a Shelly Device with
                        them, You should read these carefully;
                    </p>
                    <p>
                        <strong>Service providers</strong> - carefully selected
                        companies that provide services for or on our behalf,
                        such as providers of cloud services, customer support
                        services, e-mail and messaging services, including
                        direct marketing services, infrastructure supply and IT
                        services;
                    </p>
                    <p>
                        <strong>Professionals in various fields</strong> (such
                        as but not limited to external marketing, product and
                        service consultants, auditors, legal, finance and
                        accountancy advisors) for maintenance and improvement
                        the quality of the Service, ensuring compliance with
                        regulatory requirements, protection of our legitimate
                        rights and interests in court and administrative
                        proceedings;
                    </p>
                    <p>
                        <strong>State bodies and public authorities</strong> to
                        which we might be obliged to disclose Your Data when
                        this is required by law, legal process, administrative
                        or court order to disclose your information.
                    </p>
                    <p>
                        <strong>
                            Other parties in connection with corporate
                            transactions
                        </strong>{' '}
                        as part of a merger or transfer, acquisition or sale, or
                        in the event of bankruptcy; In this case, you will
                        receive a clear notification via email and/or our
                        website regarding the change of ownership, the
                        incompatibility of new use of personal information, and
                        the choice of personal information.
                    </p>
                </ul>
                <p>
                    In addition to the disclosures described in this Privacy
                    Notice, we may share information about You with third
                    parties when you personally consent to or request such
                    sharing.
                </p>
                <p>
                    In regards to private individuals, we require and pay
                    attention that the above stated third parties apply all
                    required technical and organizational measures for the
                    protection of the Personal Data share with them.
                </p>
            </section>
            <section>
                <h2>6. Cross-border Data Transfers</h2>
                <p>
                    Personal Data you entrust to us will primarily be processed
                    by us in Bulgaria, European Union. However, some of our
                    Third-Party Service Providers and specifically our providers
                    of e-mail messaging services for direct marketing, are not
                    located in the European Union. The main country outside the
                    European Union where your Personal Data can be processed by
                    such service providers, is the United States. All these
                    international data transfers are subject to legal
                    requirements to ensure that your personal information is
                    processed safely and as you would expect, which means your
                    Personal Data is likely to end up in other countries,
                    including outside of the European Union. We will process
                    your Personal Data for marketing purposes including by
                    sharing these with these service providers only upon your
                    explicite consent.
                </p>
            </section>
            <section>
                <h2>7. Retention of Data:</h2>
                <p>
                    The retention period of the Data depends on the legal basis
                    relied upon to process your Data.
                </p>
                <p>
                    <strong>
                        Data processed for providing You the Service (Account
                        and Device Data)
                    </strong>{' '}
                    is processed for as long as You have an active Account, or
                    as long as required under our legal obligations in the
                    respective countries we operate.
                </p>
                <p>
                    <strong>
                        Data processed based on our legitimate interest
                    </strong>{' '}
                    is only kept for as long as needed for the specific purposes
                    for which they were collected.
                </p>
                <p>
                    <strong>Data processed based on your consent</strong> are
                    retained until You withdraw your consent. Upon withdrawal of
                    Your consent, we will stop processing your Data that relies
                    on your consent, but it will not impact the processing of
                    data collected prior to Your withdrawal until the purposes
                    for which such Data were collected have been achieved.
                </p>
                <p>
                    <strong>
                        Data processed in compliance with legal obligations
                    </strong>
                    , are processed within the statutory retention period as per
                    the applicable law.
                </p>
                <p>
                    Following the expiration of the above-stated time limits,
                    the data is deleted and may not be retrieved and used any
                    longer.
                </p>
                <p>
                    The data shall not be deleted but shall continue to be
                    processed only for protection of our legitimate rights and
                    interests or in compliance with our legitimate obligations,
                    in the event that as of the date of expiration of the above
                    stated time limit there is pending court, administrative and
                    pre-court proceedings – until its closing by final juditial
                    or administrative act.
                </p>
            </section>
            <section>
                <h2>8. Your rights as Data Subject:</h2>
                <p>
                    Under data protection law, you have rights available to you
                    depending on our reason for processing your information.
                </p>
                <ol>
                    <li>
                        <strong>8.1. Right to access:</strong>
                    </li>
                    <ul>
                        <li>
                            You have the right to ask us for copies of Your
                            personal information free of charge. This right
                            always applies but there are some exemptions, which
                            means you may not always receive all the information
                            we process;
                        </li>
                        <li>
                            If we are unable to provide you with access to your
                            Personal Data because disclosure would violate the
                            rights and freedoms of third parties, we will notify
                            You of this decision.
                        </li>
                    </ul>
                    <p>
                        Only a person who can be identified by us as Service
                        User has the opportunity to exercise his/her rights
                        under this section. You may contact us and after
                        submitting a written request and verifying your identity
                        the requested information will be provided to you.
                    </p>
                    <p>
                        If We have legitimate concerns regarding Your identity,
                        we may request the provision of additional information
                        necessary to verify Your identity as a Service User. We
                        reserve the right to refuse access to the required
                        information if we are not in a position to identify the
                        individual submitting a request.
                    </p>
                    <li>
                        <strong>
                            8.2. Right to rectification of inaccurate personal
                            data:
                        </strong>
                    </li>
                    <ul>
                        <li>
                            You have the right to request the rectification of
                            inaccurate information and completion of incomplete
                            information. This you can accomplish via your
                            Service Account or by contacting us by submitting a
                            written request.{' '}
                        </li>
                    </ul>
                    <li>
                        <strong>
                            8.3. Right to erasure („The right to be forgotten“):
                        </strong>
                    </li>
                    <p>
                        The right to erasure applies to a strictly limited
                        extent as specified under the law. Removal of the need
                        to process. The right only applies in the following
                        circumstances:
                    </p>
                    <ul>
                        <li>
                            when your data are no longer necessary for the
                            original reason they were collected or used for.{' '}
                        </li>
                        <li>
                            when you initially consented to us using your data,
                            but have now withdrawn your consent.
                        </li>
                        <li>
                            when you have objected to the use of your data, and
                            your interests outweigh our interests in using it.
                        </li>
                        <li>
                            when you have objected to the use of your data for
                            direct marketing purposes.
                        </li>
                    </ul>
                    <p>
                        The right to be forgotten is{' '}
                        <strong>not an absolute right</strong> and might not be
                        respected in cases provided for by the law or because of
                        a lack of reliable verification of Your identity.
                    </p>
                    <li>
                        <strong>8.4. Right to restriction of processing</strong>
                    </li>
                    <p>
                        Right to restriction of processing shall apply to
                        temporarily limit the use of your data when they are
                        considering:
                    </p>
                    <ul>
                        <li>the accuracy of Your data is challenged, or </li>
                        <li>an objection to the use of Your Data.</li>
                        <li>
                            unlawful processing of Data but you do not want it
                            to be deleted, or
                        </li>
                        <li>
                            Data are no longer needs your data but you want the
                            organisation to keep it to create, exercise or
                            defend legal claims.
                        </li>
                    </ul>
                    <p>
                        In case of rectification or erasure of processing, we
                        will notify every recipient to whom Your Personal Data
                        has been disclosed unless this is impossible or requires
                        disproportionately huge efforts.
                    </p>
                    <li>
                        <strong>8.5. Right of Data portability</strong>
                    </li>
                    <p>
                        You have the right to get your Personal Data from us in
                        a way that is accessible and machine-readable. You also
                        have the right to ask for transferring Your data to
                        another organisation. Specifically, the right only
                        applies to data that:
                    </p>
                    <ul>
                        <li>is held electronically, and </li>
                        <li>you have provided to us.</li>
                    </ul>
                    <p>
                        Data you have provided does not just mean information
                        you have typed in, such as a username or email address.
                        It may include data that We have gathered from
                        monitoring Your activities when you have used the
                        Service. This may include usage history or data
                        processed by connected objects such as the Device.
                    </p>
                    <p>That right shall not apply:</p>
                    <ul>
                        <li>
                            to processing necessary for the performance of a
                            task carried out in the public interest;
                        </li>
                        <li>
                            where this right would affect adversely the rights
                            and freedoms of others
                        </li>
                    </ul>
                    <li>
                        <strong>
                            8.6. Right to object to the processing when it’s
                            based on legitimate interest:
                        </strong>
                    </li>
                    <p>
                        You can only object to processing when We are using your
                        data:
                    </p>
                    <ul>
                        <li>for legitimate interests;</li>
                        <li>for statistical purposes; or</li>
                        <li>for direct marketing purposes.</li>
                    </ul>
                    <p>
                        The processing of Your data will be ceased, unless there
                        are compelling legitimate grounds for the processing
                        which override Your interests, rights and freedoms or
                        for the establishment, exercise or defence of legal
                        claims.
                    </p>
                    <li>
                        <strong>
                            8.7. Right to file a complaint with the supervisory
                            authority in the Member State of your residence,
                            place of work or place of the alleged infringement
                            if you consider that the processing of personal data
                            relating to You infringes the GDPR.
                        </strong>
                    </li>
                    <p>
                        Users have the right to submit complaints or signals to
                        the to the supervisory authority at any time, in case
                        they believe the processing of their personal data
                        violates the legislation on personal data protection.
                    </p>
                    <p>
                        Without prejudice to your right of complaint to the
                        supervisory authority at any time, please contact us in
                        advance and we promise to make everything possible to
                        settle any disputes amicably.
                    </p>
                </ol>
            </section>
            <section>
                <h2>9. Information Security Measures</h2>
                <p>
                    The security, integrity, and confidentiality of your
                    Personal Data are extremely important to us. We have
                    implemented technical, contractual, organisational, and
                    physical security measures that are designed to protect our
                    User's Personal Data from unauthorised access, disclosure,
                    use, and modification. We regularly review our security
                    procedures and practices to consider appropriate new
                    technology and methods. Please be aware that, despite our
                    best efforts, no security measures are perfect or
                    impenetrable.
                </p>
            </section>
            <section>
                <h2>10. Privacy Policy Updates</h2>
                <p>
                    We may update this Privacy Policy from time to time due to
                    changes in the Service, the applicable laws and our
                    legitimate interest. You can determine when the Privacy
                    Policy was last revised by the date provided at the
                    beginning of this page.
                </p>
                <p>
                    Any changes will become effective upon publishing in the
                    application or making them available to the User in other
                    way.
                </p>
            </section>
            <section>
                <h2>11. Further information:</h2>
                <p>
                    If you have any additional questions, please do not hesitate
                    to contact us at:{' '}
                    <a href="mailto:dpo@shelly.com">dpo@shelly.com</a>
                </p>
            </section>
        </div>
    );
}
