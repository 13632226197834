import React, { useState } from 'react';
import { Dialog } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { closeRequestPopup } from '../../../redux/features/landingPageState';
import { useForm } from 'react-hook-form';
import Input from '../../../components/Input/Input';
import ConcentsCheckboxes from '../ConcentsCheckboxes/ConcentsCheckboxes';
import { emailRegex, strings } from '../../../utils/common';
import useGetSelectedPlan from '../../../hooks/useGetSelectedPlan';
import { sendRequestEmail } from '../../../service/service';
import { useFeedbackContext } from '../../../contexts/Feedback';

import './RequestPopup.scss';

export default function RequestPopup() {
    const [hasSentEmail, setHasSentEmail] = useState(false);
    const [consentsChecked, setConsentsChecked] = useState(false);

    const dispatch = useDispatch();
    const open = useSelector(
        (state) => state.landingPageState?.requestPopupOpen
    );
    const selectedPlan = useGetSelectedPlan();
    const { showFeedback } = useFeedbackContext();

    const {
        register: registerForm,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const closeHandler = () => {
        dispatch(closeRequestPopup());
        setConsentsChecked(false);
        
        setTimeout(() => {
            reset();
            setHasSentEmail(false);
        }, 500);
    };

    async function submitHandler(data) {
        data.type = selectedPlan?.planType;
        const submitData = { ...data };

        delete submitData.subscribe;
        delete submitData.terms;
        submitData.type = selectedPlan?.planType;

        try {
            await sendRequestEmail(submitData);
            setHasSentEmail(true);
        } catch (err) {
            console.log(err);
            showFeedback(err.response?.data?.message);
        }
    }

    return (
        <Dialog open={open} onClose={closeHandler}>
            <div className="requestFormWrapper">
                {hasSentEmail ? (
                    <EmailSentView title={selectedPlan?.title} />
                ) : (
                    <form onSubmit={handleSubmit(submitHandler)}>
                        <Input
                            label="Organization"
                            placeholder="Organization *"
                            id="organization"
                            registerForm={() =>
                                registerForm('organization', { required: true })
                            }
                            hasError={errors.organization}
                            errorMsg={strings.REQUIRED_VALIDATION_MESSAGE}
                        />
                        <Input
                            label="Name"
                            placeholder="Name *"
                            id="name"
                            registerForm={() =>
                                registerForm('name', { required: true })
                            }
                            hasError={errors.name}
                            errorMsg={strings.REQUIRED_VALIDATION_MESSAGE}
                        />
                        <Input
                            label="Family"
                            placeholder="Family *"
                            id="family"
                            registerForm={() =>
                                registerForm('family', { required: true })
                            }
                            hasError={errors.family}
                            errorMsg={strings.REQUIRED_VALIDATION_MESSAGE}
                        />
                        <Input
                            label="Email"
                            placeholder="Email *"
                            id="email"
                            registerForm={() =>
                                registerForm('email', {
                                    required:
                                        strings.REQUIRED_VALIDATION_MESSAGE,
                                    validate: (v) =>
                                        emailRegex.test(v) ||
                                        'Enter a valid email',
                                })
                            }
                            hasError={errors.email}
                            errorMsg={errors.email?.message}
                        />
                        <div className="input-wrapper flex">
                            <label htmlFor="text">
                                Tell us bout your needs
                            </label>
                            <textarea
                                id="text"
                                className="input"
                                rows={5}
                                {...registerForm('text', { required: true })}
                            />
                            {errors?.text && (
                                <p className="error-msg">
                                    {strings.REQUIRED_VALIDATION_MESSAGE}
                                </p>
                            )}
                        </div>
                        <ConcentsCheckboxes
                            registerForm={registerForm}
                            termsError={errors.terms}
                            setConsentsChecked={setConsentsChecked}
                        />
                        <button
                            className="btn-blue"
                            type="submit"
                            disabled={!consentsChecked}
                        >
                            Submit
                        </button>
                    </form>
                )}
            </div>
        </Dialog>
    );
}

function EmailSentView({ title }) {
    return (
        <div className="email-sent-view">
            <p>We will contact you when your offer is ready.</p>
        </div>
    );
}
