import { createSlice } from '@reduxjs/toolkit';

const userData = createSlice({
    name: 'userData',
    initialState: null,
    reducers: {
        setUser: (_state, { payload }) => {
            return payload;
        },
    },
});

export const { setUser } = userData.actions;

export const selectUserData = state => state.userData;
export const selectUserTenants = state => state.userData;

export default userData.reducer;
