import { logoutRedirectUrl } from '../service/urls';

export const logout = () => {
    const logoutAuthSettings =
        localStorage.getItem('logoutAuthSettings') &&
        JSON.parse(localStorage.getItem('logoutAuthSettings'));
    const idToken = localStorage.getItem('idToken');
    console.log('idToken', idToken);

    window.location = `${logoutAuthSettings?.baseUrl}/realms/${logoutAuthSettings?.realm}/protocol/openid-connect/logout?client_id=${logoutAuthSettings?.clientId}&post_logout_redirect_uri=${logoutRedirectUrl}&id_token_hint=${idToken}`;
};
