import Dialog from '@mui/material/Dialog';
import Stack from '@mui/system/Stack';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../authentication/authentication';
import { getTenants } from '../../service/service';
import { reactQuerySettings } from '../../utils/common';
import './Dashboard.scss';
import SetupCard from '../../components/SetupCard/SetupCard';

export default function Dashboard() {
    const [freePlanPopupOpen, setFreePlanPopupOpen] = useState(false);
    const closeFreePlanPopup = () => setFreePlanPopupOpen(false);
    const openFreePlanPopup = () => setFreePlanPopupOpen(true);
    const navigate = useNavigate();
    const { data: setups } = useQuery('setups', getTenants, {
        ...reactQuerySettings, 
    });
    const { role } = useAuth();

    return (
        <div className="dashboard">
            <h3>My setups</h3>
            <div className="flex-grow-wrapper">
                <div className="flex cards-wrapper">
                    {setups?.map((e) => (
                        <SetupCard key={e.name} {...e} />
                    ))}
                </div>
            </div>
            <Stack flexDirection="row" justifyContent="start">
                <button
                    className="btn-blue"
                    onClick={() => {
                        if (role !== 'USER') {
                            navigate('/dashboard/add-setup');
                            return;
                        }
                        openFreePlanPopup();
                    }}
                >
                    Add another setup
                </button>
            </Stack>

            <FreePlanPopup
                onClose={closeFreePlanPopup}
                open={freePlanPopupOpen}
            />
        </div>
    );
}

function FreePlanPopup({ open, onClose }) {
    return (
        <Dialog open={open} onClose={onClose}>
            <div style={{ padding: '10px' }}>
                You are at a free plan, <br />
                please upgrade for more setups.
            </div>
        </Dialog>
    );
}
