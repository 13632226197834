import { useNavigate } from "react-router-dom";
import './SetupCard.scss';

export default function SetupCard({ name, id }) {
    const navigate = useNavigate();

    return (
        <div
            className="setup-card"
            onClick={() => navigate(`/dashboard/setup/${id}`)}
        >
            {name}
        </div>
    );
}
