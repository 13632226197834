import { Dialog } from '@mui/material';
import './ContactUs.scss';
import Switch from './Switch';
import { useDispatch, useSelector } from 'react-redux';
import {
    closeContactUsPopup,
    selectLandingPageState,
} from '../../../redux/features/landingPageState';
import ConcentsCheckboxes from '../ConcentsCheckboxes/ConcentsCheckboxes';
import { useForm } from 'react-hook-form';
import { emailRegex, strings } from '../../../utils/common';

export default function ContactUs() {
    const { contactUsPopupOpen: open } = useSelector(selectLandingPageState);
    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset: resetForm,
        getValues,
    } = useForm();
    const closePopup = () => {
        resetForm();
        dispatch(closeContactUsPopup());
    };
    const values = getValues();


    const submitHandler = async (data) => {
        console.log('data', data);
    };

    return (
        <Dialog className="contact-us-wrapper" open={open} onClose={closePopup}>
            <section className="contact-us-section">
                <h3 className="section-title">Contact us</h3>
                <form onSubmit={handleSubmit(submitHandler)}>
                    <div className="flex name-section">
                        <div className="input-wrapper flex">
                            <label className="label" htmlFor="first-name">
                                First name
                            </label>
                            <input
                                className="input"
                                type="text"
                                id="first-name"
                                placeholder="First Name"
                                {...register('firstName', {
                                    required:
                                        strings.REQUIRED_VALIDATION_MESSAGE,
                                    maxLength: {
                                        value: 20,
                                        message: strings.MAX_SYMBOLS_20,
                                    },
                                })}
                            />
                            {errors.firstName && (
                                <p className="error-msg">
                                    {errors.firstName.message}
                                </p>
                            )}
                        </div>
                        <div className="input-wrapper flex">
                            <label className="label" htmlFor="last-name">
                                Last name
                            </label>
                            <input
                                className="input"
                                type="text"
                                id="last-name"
                                placeholder="Last Name"
                                {...register('lastName', {
                                    required:
                                        strings.REQUIRED_VALIDATION_MESSAGE,
                                    maxLength: {
                                        value: 20,
                                        message: strings.MAX_SYMBOLS_20,
                                    },
                                })}
                            />
                            {errors.lastName && (
                                <p className="error-msg">
                                    {errors.lastName.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="input-wrapper flex">
                        <label htmlFor="email" className="label">
                            Email Address
                        </label>
                        <input
                            id="email"
                            type="text"
                            placeholder="Email Address"
                            className="input"
                            {...register('email', {
                                required: strings.REQUIRED_VALIDATION_MESSAGE,
                                pattern: {
                                    value: emailRegex,
                                    message: strings.VALID_EMAIL,
                                },
                            })}
                        />
                        {errors.email && (
                            <p className="error-msg">{errors.email.message}</p>
                        )}
                    </div>
                    <div className="input-wrapper flex">
                        <label htmlFor="message" className="label">
                            Your message
                        </label>
                        <textarea
                            rows={4}
                            id="message"
                            placeholder="Message"
                            className="input"
                            {...register('message', {
                                required: strings.REQUIRED_VALIDATION_MESSAGE,
                                maxLength: {
                                    value: 300,
                                    message: strings.MAX_SYMBOLS_300,
                                },
                            })}
                        />
                        {errors.message && (
                            <p className="error-msg">
                                {errors.message.message}
                            </p>
                        )}
                    </div>
                    <div className="concents-section">
                        <ConcentsCheckboxes registerForm={register} />
                    </div>
                    <button className="btn-blue" disabled={!values?.terms}>
                        Send message
                    </button>
                </form>
            </section>
        </Dialog>
    );
}
