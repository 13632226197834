import React from 'react';
import { marketingCookies, statisticCookies } from '../../../utils/common';

export default function Cookies() {
    return (
        <div>
            <section>
                <h2>Cookie Policy</h2>
            </section>
            <section>
                <p>
                    <strong>(In force from 10.07.2022)</strong>
                </p>
            </section>
            <section>
                <h2>What are cookies?</h2>
                <p>
                    This website uses cookies. We use cookies to personalise
                    content and ads, to provide social media features and to
                    analyse our traffic. We also share information about your
                    use of our site with our social media, advertising and
                    analytics partners who may combine it with other information
                    that you’ve provided to them or that they’ve collected from
                    your use of their services. You consent to our cookies if
                    you continue to use our website.
                </p>
                <p>
                    Cookies are small text files that can be used by websites to
                    make a user's experience more efficient
                </p>
            </section>
            <section>
                <h2>How do we use cookies?</h2>
                <p>
                    We use cookies on our site in order to ensure its
                    availability and usability, provide certain functionalities
                    (eg remembering user preferences for language, settings,
                    etc.), improving its work and analyzing information about
                    user behavior in its use.
                </p>
                <p>
                    The information gathered from the cookies on our site is
                    used in a generic way, which enables us to optimize websites
                    and to improve their structure, functionality and content.
                </p>
                <p>
                    The law states that we can store cookies on your device if
                    they are strictly necessary for the operation of this site.
                    For all other types of cookies we need your permission.
                </p>
            </section>
            <section>
                <h2>What cookies do we use?</h2>
                <p>This site uses different types of cookies.</p>
                <p>
                    You can at any time change or withdraw your consent from the
                    Cookie Declaration on our website.
                </p>
                <p>Necessary (8)</p>
                <p>
                    Necessary cookies help make a website usable by enabling
                    basic functions like page navigation and access to secure
                    areas of the website. The website cannot function properly
                    without these cookies.
                </p>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Provider</th>
                            <th>Purpose</th>
                            <th>Expiry</th>
                            <th>Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>__cfduid [x2]</td>
                            <td>cookiebot.zendesk.com zdassets.com</td>
                            <td>
                                Used by the content network, Cloudflare, to
                                identify trusted web traffic.
                            </td>
                            <td>1 year</td>
                            <td> HTTP Cookie</td>
                        </tr>
                        <tr>
                            <td>__cfruid</td>
                            <td>cookiebot.zendesk.com</td>
                            <td>
                                This cookie is a part of the services provided
                                by Cloudflare - Including load-balancing,
                                deliverance of website content and serving DNS
                                connection for website operators.
                            </td>
                            <td>Session</td>
                            <td>HTTP Cookie </td>
                        </tr>
                        <tr>
                            <td>__RequestVerificationToken</td>
                            <td>cookiebot.com</td>
                            <td>
                                Helps prevent Cross-Site Request Forgery (CSRF)
                                attacks.
                            </td>
                            <td>Session</td>
                            <td>HTTP Cookie</td>
                        </tr>
                        <tr>
                            <td>ASP.NET_SessionId</td>
                            <td>cookiebot.com</td>
                            <td>
                                Preserves the visitor's session state across
                                page requests.
                            </td>
                            <td>Session</td>
                            <td>HTTP Cookie</td>
                        </tr>
                        <tr>
                            <td>ASPXAUTH</td>
                            <td>cookiebot.com</td>
                            <td>
                                Identifies the user and allows authentication to
                                the server
                            </td>
                            <td>Session</td>
                            <td>HTTP Cookie</td>
                        </tr>
                        <tr>
                            <td>CookieConsent</td>
                            <td>cookiebot.com</td>
                            <td>
                                Stores the user's cookie consent state for the
                                current domain
                            </td>
                            <td>1 year</td>
                            <td>HTTP Cookie</td>
                        </tr>
                        <tr>
                            <td>ZD-suid</td>
                            <td>cookiebot.com</td>
                            <td>
                                Unique id that identifies the user’s session.
                            </td>
                            <td>Persistent</td>
                            <td> HTML Local Storage</td>
                        </tr>
                    </tbody>
                </table>
                <p>Preferences (3) </p>
                <p>
                    Preference cookies enable a website to remember information
                    that changes the way the website behaves or looks, like your
                    preferred language or the region that you are in. You can
                    change your cookie preferences at any time by clicking on
                    the Cookies Settings. You can then adjust the available
                    sliders to ‘On’ or ‘Off’, then clicking ‘Save and close’.
                    You may need to refresh your page for your settings to take
                    effect.
                </p>
                <p>
                    Alternatively, most web browsers allow some control of most
                    cookies through the browser settings. To find out more about
                    cookies, including how to see what cookies have been set,
                    visit{' '}
                    <a
                        href="https://www.aboutcookies.org"
                        target="_blank"
                        rel="noreferrer"
                    >
                        www.aboutcookies.org
                    </a>{' '}
                    or{' '}
                    <a
                        href="https://www.allaboutcookies.org"
                        target="_blank"
                        rel="noreferrer"
                    >
                        www.allaboutcookies.org
                    </a>
                    .
                </p>

                <p>Find out how to manage cookies on popular browsers:</p>

                <ul className="list-bullet">
                    <li>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en"
                        >
                            Google Chrome
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://support.microsoft.com/en-gb/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd"
                        >
                            Microsoft Edge
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US"
                        >
                            Mozilla Firefox
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://support.microsoft.com/en-gb/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                        >
                            Microsoft Internet Explorer
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://help.opera.com/en/latest/web-preferences/"
                        >
                            Opera
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://support.apple.com/en-gb/safari"
                        >
                            Apple Safari
                        </a>
                    </li>
                </ul>

                <p>
                    To find information relating to other browsers, visit the
                    browser developer's website.
                </p>
                <p>
                    To opt out of being tracked by Google Analytics across all
                    websites, visit{' '}
                    <a
                        href="http://tools.google.com/dlpage/gaoptout"
                        target="_blank"
                        rel="noreferrer"
                    >
                        http://tools.google.com/dlpage/gaoptout
                    </a>
                    .
                </p>

                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Provider</th>
                            <th>Purpose</th>
                            <th>Expiry</th>
                            <th>Type</th>
                        </tr>
                    </thead>
                    <tr>
                        <td>userlang</td>
                        <td>cookiebot.com</td>
                        <td>
                            Remembers the user's selected language version of a
                            website
                        </td>
                        <td>1 year</td>
                        <td>HTTP Cookie</td>
                    </tr>
                    <tr>
                        <td>ZD-settings</td>
                        <td>cookiebot.com</td>
                        <td>
                            Arbitrary and anonymous settings for the user's
                            preferences when browsing a Zendesk knowledge base.
                        </td>
                        <td>Persistent</td>
                        <td>HTML Local Storage</td>
                    </tr>
                    <tr>
                        <td>ZD-store</td>
                        <td>cookiebot.com</td>
                        <td>
                            Registers whether the self-service-assistant Zendesk
                            Answer Bot has been displayed to the website user.
                        </td>
                        <td>Persistent</td>
                        <td>HTML Local Storage</td>
                    </tr>
                </table>

                <p>Statistics (5)</p>
                <p>
                    Statistic cookies help website owners to understand how
                    visitors interact with websites by collecting and reporting
                    information anonymously.
                </p>

                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Provider</th>
                            <th>Purpose</th>
                            <th>Expiry</th>
                            <th>Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        {statisticCookies.map((e, i) => (
                            <TableRow key={i} {...e} />
                        ))}
                    </tbody>
                </table>

                <p>Marketing (22)</p>

                <p>
                    Marketing cookies are used to track visitors across
                    websites. The intention is to display ads that are relevant
                    and engaging for the individual user and thereby more
                    valuable for publishers and third party advertisers.
                </p>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Provider</th>
                            <th>Purpose</th>
                            <th>Expiry</th>
                            <th>Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        {marketingCookies.map((e, i) => (
                            <TableRow key={i} {...e} />
                        ))}
                    </tbody>
                </table>
            </section>
            <section>
                <h2>"Third Party Cookies"</h2>
                <p>
                    Some cookies are placed by third party services that appear
                    on our pages. We have no control over the generation and
                    management of third-party cookies.
                </p>
                <p>
                    Most publicly available browsers (browsers) allow cookies to
                    be set on the destination device by default. If you prefer,
                    you can change your browser settings so that your existing
                    cookies are deleted or the placement of cookies (including
                    "third party cookies") is automatically blocked. Please note
                    that if you choose to delete or block cookies, this may
                    affect the accessibility or functionality of this website.
                </p>
                <p>
                    For more information on the purpose of third-party cookies
                    and their content, please consult and understand the privacy
                    policies and use of cookies adopted by their respective
                    third parties.
                </p>
            </section>
            <section>
                <h2>Manage the cookies we use</h2>
                <p>
                    Most commonly available browsers (browsers) allow you to
                    place cookies on the end device. If you prefer, you can
                    change your browser settings in such a way that existing
                    cookies are deleted or cookies (including third-party
                    cookies) are automatically blocked. Please note that if you
                    choose to delete or block cookies, this may affect the
                    accessibility or functionality of this website.
                </p>
                <p>
                    More information about cookies, including how to manage
                    them, can be found at the following websites:
                    <a href="http://www.allaboutcookies.org/manage-cookies/">
                        http://www.allaboutcookies.org/manage-cookies/
                    </a>{' '}
                    and{' '}
                    <a href="https://matomo.org/gdpr-analytics/?menu">
                        https://matomo.org/gdpr-analytics/?menu
                    </a>
                    . We note that the content of{' '}
                    <a href="http://www.allaboutcookies.org/manage-cookies/">
                        http://www.allaboutcookies.org/manage-cookies/
                    </a>{' '}
                    is only available in the following languages: English,
                    German, Spanish and French and the content of{' '}
                    <a href="https://matomo.org/gdpr-analytics/?menu">
                        https://matomo.org/gdpr-analytics/?menu
                    </a>{' '}
                    is available in English only
                </p>
                <p>
                    You can use Google's cookie unsubscribe option Analytics,
                    located at the following address:{' '}
                    <a href="https://tools.google.com/dlpage/gaoptout/">
                        https://tools.google.com/dlpage/gaoptout/
                    </a>
                </p>
                <p>
                    Follow the described steps to unsubscribe from Google's
                    cookies Analytics, will install superstructure "Add-on" of
                    your browser, which essentially would block the
                    functionality of "cookies" on Google Analytics .
                </p>
                <p>
                    We reserve the right to update this Cookie Policy at any
                    time, without notice, by posting the new or revised Cookie
                    Policy on the Website.
                </p>
                <p>
                    Learn more about who we are, how you can contact us and how
                    we process personal data in our Privacy Policy.
                </p>
            </section>
        </div>
    );
}

function TableRow({ name, provider, purpose, expiry, type }) {
    return (
        <tr>
            <td>{name}</td>
            <td>{provider}</td>
            <td>{purpose}</td>
            <td>{expiry}</td>
            <td>{type}</td>
        </tr>
    );
}
