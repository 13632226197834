import React from 'react';

export default function Terms() {
    return (
        <div>
            <section>
                <h2>CONTENT:</h2>
                <ul>
                    <li>
                        1. INTRODUCTIОN and DATA CONCERNING THE SERVICE PROVIDER
                    </li>
                    <li>2. DEFINITIONS</li>
                    <li>3. SUBJECT MATTER</li>
                    <li>4.1.SHELLY CLOUD SERVICE (Shelly Access Control)</li>
                    <li>4.2.SHELLY PREMIUM SERVICE TERMS AND CONDITIONS</li>
                    <li>5. RIGHTS AND OBLIGATIONS OF THE SERVICE USER</li>
                    <li>6. LIABILITY</li>
                    <li>7. PERSONAL DATA</li>
                    <li>8. INTELLECTUAL PROPERTY</li>
                    <li>9. MISCELLANEOUS</li>
                </ul>
            </section>
            <section>
                <h2>
                    1. INTRODUCTIОN and DATA CONCERNING THE SERVICE PROVIDER
                </h2>
                <ul>
                    <li>
                        1.1 Shelly Cloud Service branded Shelly Access Control
                        is a digital service that is provided by Shelly Europe
                        Ltd., registered in the Commercial Register at the
                        Registry Agency with a seat and managing address in the
                        city of Sofia, No 103 Cherni Vrah Blvd., Bulgaria.
                    </li>
                    <li>
                        1.2 Contact data with Shelly Europe Ltd. during business
                        days from 09:00 until 17:00 CET:
                        <ul>
                            <li> tel: +359 2 988 6954</li>
                            <li> e-mail: support@infinno.com</li>
                        </ul>
                    </li>
                </ul>
            </section>
            <section>
                <h2>2. DEFINITIONS</h2>
                <ul>
                    <li>
                        2.1. All terms used in these General Terms and
                        Conditions, irrespective of whether they are in singular
                        or in plural, shall have the meaning stated herein:
                    </li>
                    <ul>
                        <li>
                            <strong>I. Shelly Service/Service</strong> – is a
                            digital service branded{' '}
                            <strong>Shelly Access Control</strong>, consisting
                            of web-based platform for access management and
                            control (“the Web Service”) and a mobile application
                            for personalized use (“the App Service”), that
                            allows remote access control of Shelly Access
                            Devices and the appliances to which these are
                            accessible via Shelly Access Control App or online
                            via{' '}
                            <a href="www.accesscontrol.shelly.com">
                                www.accesscontrol.shelly.com
                            </a>
                            ;
                        </li>
                        <li>
                            <strong>II. Service Provider</strong> – shall mean
                            the developer and owner of the Shelly Access Control
                            – Shelly Europe Ltd
                        </li>
                        <li>
                            <strong>III. Service User</strong> – any person or
                            legal entity which is using the Service for
                            his/her/its personal needs, regardless of the user
                            category (Master Admin, Admin, Technician or User) -
                            Master User as well as any Authorized User;
                        </li>
                        <li>
                            <strong>IV. Shelly Controllers</strong> – any of the
                            following Shelly Plus 1, Shelly Plus 1PM, Shelly
                            Plus 2PM as well as any other device branded
                            „Shelly” / “Shelly Quibino” in all models and
                            modifications, which enable the remote management,
                            monitoring, and control of access periphery (such as
                            doors, windows, locks, etc.).
                        </li>
                        <li>
                            <strong>V. Documentation</strong> – all required by
                            law documents accompanying the Shelly Device,
                            including any form of User and Safety Guide and the
                            Knowledge base available at{' '}
                            <a href="https://kb.shelly.cloud/knowledge-base/">
                                https://kb.shelly.cloud/knowledge-base/
                            </a>
                            , as well as any other documentation regarding the
                            use of the Service available at{' '}
                            <a href="www.accesscontrol.shelly.com">
                                www.accesscontrol.shelly.com
                            </a>
                        </li>
                        <li>
                            <strong>VI. App Service</strong> – Personalized
                            registration with the Shelly Access Control created
                            by the Web Service User, though which the App User
                            is provided access to specific Shelly Access
                            Devices;
                        </li>
                        <li>
                            <strong>VII. Web Service</strong> – The web-based
                            platform for access management and control Shelly
                            Access Control available at{' '}
                            <a href="www.accesscontrol.shelly.com">
                                www.accesscontrol.shelly.com
                            </a>
                        </li>
                        <li>
                            <strong>VIII. Master User</strong> – the owner of
                            the main Service account responsible for the
                            management of the access control which has all
                            rights for management of the access control system
                            and the authorization of further Service Users with
                            specific access rights;
                        </li>
                        <li>
                            <strong>IX. Authorized User</strong> – any Service
                            User who has been authorized by the Master User;
                        </li>
                        <li>
                            <strong>X. Terminal Device</strong> – any computer,
                            laptop, tabled, mobile phone or other device through
                            which the Service is accessed and used by the
                            Service User;
                        </li>
                        <li>
                            <strong>XI. Service Account</strong> – the main
                            account that was initially created with the Web
                            Service and through which the access to different
                            premises is manages.
                        </li>
                    </ul>
                </ul>
            </section>
            <section>
                <h2>3. SUBJECT MATTER</h2>
                <ul>
                    <li>
                        3.1. These General Terms and Conditions govern the
                        relations between the Service Provider and each Service
                        User concerning the use of the Shelly Service.
                    </li>
                    <li>
                        3.2 These General Terms and Conditions do not apply to
                        the products branded Shelly, as long as the usage of
                        Shelly Access Control is not a condition for the proper
                        functioning of the Shelly Devices.
                    </li>
                    <li>
                        3.3 These General Terms and Conditions are mandatory for
                        all Service Users.
                    </li>
                    <li>
                        3.4 For using the Service, a person or an entity shall
                        register a Service Account with the Web Service and
                        create App-Accounts for authorized App-Users determining
                        their access rights. By registering a Master Account or
                        accessing the App Service, the Service User thereby
                        declares that he/she has read, accepts, and is obliged
                        to comply with these General Terms and Conditions
                        unconditionally. The Service User is further obliged to
                        comply with all other conditions by the law, even if not
                        explicitly mentioned in these General Terms and
                        Conditions. In case the Service User disagrees with
                        these General Terms and Conditions, he/she shall not use
                        the Shelly Service.
                    </li>
                    <li>
                        3.5 The Service Provider shall not be liable if the
                        Service User has not read these General Terms and
                        Conditions. The Service Provider shall not be liable for
                        any damages and/or losses arising from non-compliance on
                        the part of the Service User with the provisions of
                        these General Terms and Conditions and/or the applicable
                        law.
                    </li>
                    <li>
                        3.6 The General Terms and Conditions may be amended
                        unilaterally by the Service Provider at any time at its
                        discretion or if the amendments are imposed by an
                        effective statutory act without explicit prior
                        notification to the Service Users. These amendments
                        enter into force immediately and are mandatory for all
                        Service Users, as of the date of their publishing by the
                        Service Provider.
                    </li>
                    <li>
                        3.7 If any part of these General Terms and Conditions
                        shall be held or declared to be invalid or unenforceable
                        for any reason by any court and/or competent
                        jurisdiction, such provision shall not affect the
                        validity and enforceability of the other provisions of
                        these General Terms and Conditions.
                    </li>
                </ul>
            </section>
            <section>
                <ul>
                    <li>
                        4.1 The Shelly Access Service enables remote management,
                        monitoring, and control of access management systems of
                        Shelly Controllers.
                    </li>
                    <li>
                        4.2 Shelly Access Devices can work stand-alone in a
                        local Wi-Fi network or they can also be operated through
                        cloud automation services, such as Shelly Service, as
                        well as any other similar service(s) offered by other
                        companies with which these Shelly Devices are
                        compatible. The usage of such cloud services is not a
                        condition for the Shelly Access Device proper
                        functioning.
                    </li>
                    <li>
                        4.3 The Shelly Access Control Service consists of
                        web-based management platform and thereto related mobile
                        application.
                        <ul>
                            <li>
                                4.3.1 The web-based management platform is
                                accessible only online via an Internet browser
                                at:{' '}
                                <a href="www.accesscontrol.shelly.com">
                                    www.accesscontrol.shelly.com
                                </a>
                            </li>
                            <li>
                                4.3.2 The application is available for
                                downloading free of charge in AppStore and
                                Google Play.
                            </li>
                        </ul>
                    </li>
                    <li>
                        4.4 For using the mobile version as well as the web
                        version of the Service the User’s equipment shall meet
                        the standard technical requirements for the User’s
                        digital environment, including but not limited to a
                        fully operational and equipped terminal device with
                        monitor, processor, RAM, operating system, Internet
                        connectivity, input facility, power supply, and etc. A
                        stable and persistent Internet connection is required
                        for the proper functioning of the Service and the use of
                        its features.
                        <ul>
                            <li>
                                4.4.1 The usage of the Service requires the
                                registration of a Master Account. For security
                                reasons, the Shelly Access Device(s) which
                                belong to the access management system are
                                associated with only one Master Account.
                            </li>
                            <li>
                                4.4.2 The owner of the Master Account can add
                                further Users providing them specific access
                                rights. Depending on the type of authorization
                                provided by the owner of the Master Account the
                                authorized Service Users are divided into the
                                following categories: Admin, Technician and User
                                and can also be added to different access
                                groups.
                            </li>
                            <li>
                                4.4.3 The authorized Service Users can access
                                the Service only through the App Service using
                                the credentials, provided by the Web Service on
                                behalf of the Master Account.
                            </li>
                        </ul>
                    </li>
                    <li>
                        4.5 The registration of a User Account (regardless of
                        the user’s category and the version of the Service) with
                        the Shelly Service requires the following data about the
                        Service User:
                        <ul>
                            <li>1. e-mail address;</li>
                            <li>2. password;</li>
                            <li>3. time zone;</li>
                        </ul>
                        <ul>
                            <li>
                                4.5.1 It is possible to register a Master
                                Account on behalf of, or to the benefit of an
                                organization with which the account owner is
                                associated. In this case, the Service User
                                acknowledges that he/she has the legal right
                                and/or the authorization to bind that
                                organization with these Terms and Conditions.
                                Service Users might be asked to provide
                                additional registration details, such as company
                                name, department, and VAT number (if any).
                            </li>
                            <li>
                                4.5.2 As long as the Service Users are
                                authorized by the Master Account, which also
                                determines their access rights, the owner of the
                                Master Account declares that he/she is entitled
                                to provide the said registration data to the
                                Service provider.
                            </li>
                            <li>
                                4.5.3 The Master Admin and any authorized
                                Service User are responsible for maintaining the
                                confidentiality of their account credentials and
                                to the extent permitted by the applicable law
                                agree to take responsibility for all activities
                                that occur under their Accounts. Any Service
                                User should take all necessary steps to ensure
                                that the password is kept confidential. Further,
                                only the Service User with Master Account is
                                entitled to provide, manage and revoke the
                                authorization of other Service Users.
                            </li>
                        </ul>
                    </li>
                    <li>
                        4.6 The Shelly Service provides the following basic
                        features:
                        <ul>
                            <li>
                                I. monitoring and control of access periphery
                                (doors, locks, windows) to which Shelly Devices
                                were attached;
                            </li>
                            <li>
                                II. monitoring, control, and management of
                                Users, Groups, Areas, Access Points and access
                                facilities to which Shelly Devices were
                                attached;
                            </li>
                            <li>
                                III. create, edit or delete actions custom
                                schedules, actions and operational modes of the
                                Shelly Device;
                            </li>
                            <li>
                                IV. real-time notifications for access events
                                (when enabled);
                            </li>
                            <li>V. setting up Access zones and alarms;</li>
                            <li>
                                VI. managing Service Users, including their
                                access rights and Service activity;
                            </li>
                        </ul>
                        <ul>
                            <li>
                                4.6.1 The Service User should be aware that the
                                available features and functions of the Service
                                depend on i) the specific Shelly Access
                                Device(s) type and model, as well as ii) the
                                Service User’s category, whereas not all
                                features and functions might be available in
                                respect of the specific device and the specific
                                user.
                            </li>
                            <li>
                                4.6.2 Due to the development of new models and
                                upgrades of the Shelly Access Devices and the
                                updates of the Service it is possible that
                                features and functions of the Service may be
                                missing, replaced with others or new ones might
                                be available. The Service Provider is free to
                                update or modify the Service and its features
                                and functionalities from time to time as well as
                                to disable certain functions or features or to
                                enable new ones from time to time.
                            </li>
                            <li>
                                4.6.3 To improve the user experience and
                                optimize the Service, the Service Provider
                                reserves the right but is not obliged to
                                replace, modify, and update versions of the
                                Service. When a new version of the Service is
                                released, the Service Provider does not
                                guarantee that older versions of the Service,
                                including all their features, will continue to
                                be available or fully operational, whereas the
                                Service Provider will notify the Service User
                                promptly.
                            </li>
                            <li>
                                4.6.4 The Service Provider shall not be held
                                liable for any loss or damages that occurred as
                                a result of replacement, modification, or update
                                of the Service and/or its features, including
                                but not limited to the unavailability,
                                inaccessibility or lack of support to previous
                                version(s) of the Service and/or its features;
                            </li>
                            <li>
                                4.6.5 The Service Provider uses reasonable care
                                and skill to keep the Shelly Access Service
                                operational and provides the Service User with
                                the user experiences that are normally expected
                                for this type of Service. However, the Shelly
                                Access Service features and their availability
                                may change from time to time whereas these may
                                experience interruptions due to technical
                                difficulties, maintenance, and security reasons,
                                testing, necessary updates of the Service or
                                other circumstances beyond the Provider’s
                                control. The Service Provider shall not be held
                                liable for any loss or damages that occur as a
                                result of any unavailability or inaccessibility
                                of the Service.
                            </li>
                        </ul>
                    </li>
                    <li>
                        4.7 In case the Service User has subscribed to receiving
                        marketing information through channels such as e-mail,
                        push-up notification or other communication channels
                        he/she has an easily accessible way to opt out at any
                        time through the manual setting or via the active link
                        provided at the bottom of the marketing message.
                    </li>
                    <li>
                        4.8 Following the registration of a Master Account and
                        the installation of the App Service (unless when
                        accessed online via an Internet browser), the following
                        requirements for the functioning of the Service shall be
                        met:
                        <ul>
                            <li>
                                I. Proper installation and setup of Shelly
                                Access Device(s) as per the technical
                                requirements in the Documentation;
                            </li>
                            <li>
                                II. Proper setup of the wi-fi network and
                                connectivity of the Service and the Shelly
                                Access Devices as per the Service Guideline and
                                the Documentation;
                            </li>
                            <li>
                                III. Configurated User accounts and access
                                rights;
                            </li>
                            <li>
                                IV. Successful setup of the Shelly Access
                                Device(s) with the Service as per the Service
                                Guideline and the Documentation;
                            </li>
                            <li>
                                V. Properly functioning Shelly Access Device(s);
                            </li>
                            <li>
                                VI. Stable Internet connection, including of the
                                Wi-Fi network;
                            </li>
                            <li>
                                VII. Usage of Terminal Device with the necessary
                                technical capacity (software and hardware);
                            </li>
                            <li>
                                VIII. Appropriate Internet, Bluetooth and Wi-Fi
                                settings as per the Documentation;
                            </li>
                            <li>
                                IX. Installed the latest available updates of
                                the Service and the firmware of the Shelly
                                Device(s), including security updates (Shelly
                                devices are delivered with factory-installed
                                firmware whereas updates might be necessary to
                                keep the devices in conformity);
                            </li>
                            <li>
                                X. Further requirements as might be available in
                                the Documentation of the relevant Shelly Device
                                or the Knowledge base or any Documentation;
                            </li>
                        </ul>
                        <ul>
                            <li>
                                4.8.1 The Service User shall ensure on his own
                                the Internet connectivity and settings of the
                                local and mobile network necessary for the
                                proper operation of the Shelly Device(s) and the
                                Service. The Service Provider shall not be
                                liable for the improper functioning of the
                                Shelly Device(s) and/or the Service when it is
                                due to lack of or low quality of Internet
                                connectivity.
                            </li>
                            <li>
                                4.8.2 For security reasons, each Device
                                possesses a unique ID number, which can be
                                associated only with one Master Account. The
                                Service User who has first added a Shelly Device
                                to his/her User Account becomes the Main User
                                and may at his/her discretion and if the Service
                                allows, share the Shelly Device data with other
                                Users by providing Device access level. Please
                                note that sharing Shelly Device with other Users
                                does not authorize access to the Master Admin
                                Account. Authorizing access to a User Account
                                automatically provides access to all data for
                                the Shelly Device(s) added with that User
                                Account. Therefore, we recommend sharing Device
                                data with third parties rather than Account
                                data. In either case, you bear the
                                responsibility for such action and all the risks
                                thereof.
                            </li>
                            <li>
                                4.8.3 The Service Provider shall not be liable
                                for any loss or damage that occur as a result of
                                the improper functioning of the Shelly Access
                                Service if any of the conditions stated in these
                                General Terms and Conditions, the Documentation
                                of the Shelly Access Device, the Knowledgebase
                                or the Shelly Service Guidance is not met.
                            </li>
                            <li>
                                4.8.4 The Service Provider shall not be liable
                                for any lack of conformity of the Shelly Service
                                caused by the failure of the Service User to
                                install the provided updates of the Service or
                                the device’s firmware. If updates of the Service
                                and/or the Device are necessary to keep the
                                Shelly Access Service in conformity, including
                                security updates, all updates are provided free
                                of charge. The choice to install or not any
                                updates is at Service User’s sole
                                responsibility.
                            </li>
                            <li>
                                4.8.5 The Service Provider shall not be
                                responsible to the Service User for payment of
                                any compensation to the Service User, due to any
                                loss or damages as a result of any service
                                outages or failures that are caused by third
                                parties or events beyond the Service Provider’s
                                control.
                            </li>
                            <li>
                                4.8.6 The Service Provider is free at any time
                                to change the terms and conditions under which
                                the Service is provided. If there are changes
                                that may negatively affect the User, he/she will
                                be notified in advance before these changes
                                enter into force and will be provided the
                                opportunity to terminate his/her registration as
                                per the rules under these General Terms and
                                Conditions. It is accepted that the User agrees
                                to the amendment of these Terms and Conditions
                                if the Service User continues to use it after
                                the notification and when he/she hasn’t
                                terminated his/her registration immediately or
                                within the time provided (if applicable).
                            </li>
                        </ul>
                    </li>
                </ul>
            </section>
            <section>
                <h2>5. LEVEL OF SERVICE</h2>
                <ul>
                    <li>
                        5.1. The Service Provider will do its best to ensure the
                        compliance of the Service with its purpose, technical
                        specifications (such as functionality, compatibility,
                        interoperability and other features) as per these
                        General Terms and Conditions and any thereto related
                        Device Documentation, the applicable legal and technical
                        standards.
                    </li>
                    <li>
                        5.2 Notwithstanding anything of the foregoing, the
                        Service Provider does not guarantee that the Service
                        will be operational without any disruptions, bugs or
                        errors.
                    </li>
                    <li>
                        5.3 The Shelly Access Service shall not be considered as
                        non-conformant when the conditions for the functioning
                        of the Service, including the user and safety
                        requirements for the usage of the Shelly Devices as per
                        the Documentation, were not met by the User, as well as
                        when the non-conformity is due to reasons beyond the
                        control of the Service Provider.
                    </li>
                    <li>
                        5.4 Taking into account that the Shelly Access Service
                        is provided free of charge, in case of non-conformity of
                        the Service, the Service User is entitled to terminate
                        this service agreement immediately by ceasing to use the
                        Service. In case of termination, the subscription of the
                        User is to be terminated. Upon termination of the
                        Service agreement the Service Provider is entitled to
                        limit, as well as to completely cease the access of the
                        User to the Access Service and its features. The User is
                        entitled to any compensation whatsoever related to the
                        termination of this Service Agreement;
                    </li>
                </ul>
            </section>
            <section>
                <h2>6. RIGHTS AND OBLIGATIONS</h2>
                <ul>
                    <li>
                        6.1 By using the Service, the Service User is obliged to
                        comply with these General Terms and Conditions, as well
                        as with all other conditions imposed by the local
                        applicable laws and international law, even if not
                        explicitly mentioned in these General Terms and
                        Conditions. Subject to the compliance of the Service
                        User with these General Terms and Conditions, the
                        Service Provider grants to the Service User limited,
                        non-exclusive, revocable permission to make personal,
                        non-commercial use of the Shelly Service. The use shall
                        remain in effect unless and until terminated by the
                        Service User or the Service Provider.
                    </li>
                    <li>
                        6.2 The Service Provider has the right at its own
                        discretion to suspend, prohibit or limit access to the
                        Service and/or some of its features temporarily or
                        permanently by a specific User or in general in case of
                        an established or assumed abuse by the Service User or
                        third parties of these General Terms and Conditions, the
                        applicable law or any third party rights, as well as in
                        compliance with an order of a competent authority and /
                        or in fulfilment of a statutory obligation and in cases
                        of unusually high traffic and other technical grounds
                        and security reasons. The Service Provider shall not be
                        liable for any eventual or sustained loss or damages of
                        the Service User that may occur as a result of such
                        actions nor is the Service Provider obliged to provide
                        any explanation or enter into any correspondence with
                        the Service Use.
                        <ul>
                            <li>
                                6.2.1 The Service User is obliged to and is
                                liable for all provided Data in connection to
                                the registration with the Service – to be true,
                                complete, accurate and up-to-date as of the date
                                of the registration. Service
                            </li>
                            <li>
                                6.2.2 The Service Provider shall not be liable
                                for any loss or damages that may occur due to
                                the provision of incorrect, incomplete or false
                                data (for example inability to access the
                                account, to use the Service, to change profile
                                settings, to receive customer support etc.)
                            </li>
                        </ul>
                    </li>
                    <li>
                        6.3 The Service User is solely responsible for his/her
                        activity and interactions with the Service, including
                        for keeping his/her account password safe. The Service
                        Provider shall not be held liable for any damages and/or
                        loss caused by any unauthorized use of the Service due
                        to acts and omissions on the part of the Service User.
                        The Service User must immediately notify the Service
                        Provider in case of breach of security or unauthorized
                        use of his/her account.
                    </li>
                    <li>
                        6.4 The Service User is responsible to check in advance
                        whether the Terminal Devices through which the Shelly
                        Access Service is going to be used comply with the
                        technical requirements necessary for the proper
                        functioning of the Service. The Service Provider shall
                        not be liable for any loss and/or damages in case of the
                        improper or impossible functioning of the Shelly Access
                        Service, when the Service User has not complied with
                        his/her obligations for ensuring the minimal technical
                        conditions for its use.
                    </li>
                    <li>
                        6.5 The Service User shall not:
                        <ul>
                            <li>
                                1. use the Service in any way that could damage
                                the Service or the Service Provider’s general
                                business, rights and interests of third parties;
                            </li>
                            <li>
                                2. use any “deep-link”, “robot”, or other
                                automatic or manual device, software, program,
                                code, algorithm or methodology, to access, copy
                                or monitor any portion of the Service, or in any
                                way reproduce or circumvent the navigational
                                structure or presentation of the Service;
                            </li>
                            <li>
                                3. gain or attempt to gain unauthorized access
                                to any portion or features of the Service or any
                                other system or network connected to the
                                Service, by hacking, “password-mining” or using
                                any other illegitimate method of accessing data;
                            </li>
                            <li>
                                4. probe, scan or test the vulnerability of the
                                Service or any network connected to the Service,
                                nor breach the security or authentication
                                measures of the Service or any network connected
                                to the Service;
                            </li>
                            <li>
                                5. use any device, technology or method to
                                interfere or attempt to interfere with the
                                proper functioning ofthe Service and its
                                features or any transactions occurring through
                                the Service, or with any other person’suse of
                                the Service, such as computer virus and/or
                                malware/malicious software.
                            </li>
                        </ul>
                    </li>
                    <li>
                        6.6 In case of violation of the Terms and Conditions by
                        the Service User, the Service Provider shall reserve the
                        right to prohibit, suspend access to the Service
                        temporarily or permanently. In case of detected
                        suspicious activity or other vulnerable actions, the
                        Service Provider is entitled to suspend immediately
                        without prior notice the access to the Service or a
                        specific Account temporarily or permanently. The Service
                        Provider shall not be liable for any eventual or
                        sustained loss or damages of the Service User that may
                        occur as a result of such actions nor is the Service
                        Provider obliged to provide any explanation or enter
                        into any correspondence with the Service Use.
                    </li>
                </ul>
            </section>
            <section>
                <h2>7. LIABILITY</h2>
                <ul>
                    <li>
                        7.1 In order to ensure the smooth usage of the Shelly
                        Service, its error free performance, security features,
                        and to extend of its functionalities the Service
                        Provider might release from time-to-time updated
                        versions of the Service. The Service Provider shall
                        notify the Service User for any available updates via
                        his/her registered e-mail or within the Service using
                        personal or pop-up notifications.
                    </li>
                    <li>
                        7.2 The Service User is solely responsible for
                        installing the offered updates. The Service Provider
                        shall not be held liable for any loss or damages in case
                        any malfunctions of the Service occur or the Service
                        became fully or partially unusable due to the usage of
                        out-of-date version of the Service.
                    </li>
                    <li>
                        7.3 The Service Provider shall not be held liable for
                        any loss suffered by the Service User due to reasons
                        attributable to third parties such as communication line
                        failures, technical problems, network or mobile terminal
                        device failures, system instability issues, and any
                        force majeure factors.
                    </li>
                    <li>
                        7.4 The Service Provider does not undertake
                        responsibility for the availability of and/or the data
                        processing by third-party services with which the
                        Service User has shared access to his/her User Account
                        or Shelly Device.
                    </li>
                    <li>
                        7.5 The Service Provider will do its utmost to ensure
                        that the Service and the technology and information
                        involved are safe, effective, accurate, and reliable. By
                        using the Service under these General Terms and
                        Conditions the Service User acknowledges to be aware
                        that the Service itself as well as the information
                        provided thereby are dependent on a complex of technical
                        conditions, the User understands that the Service
                        provider does not represent, warrant or guarantee the
                        accuracy, completeness or timeliness of the Service and
                        shall not be liable for any loss or damages related to
                        the lack or incorrectness of the Service and the
                        information provided thereby.
                    </li>
                    <li>
                        7.6 The Service, like most other Internet and mobile
                        services, may be affected by differences in factors
                        including but not limited to the network service
                        quality, and social environment, and may also be subject
                        to intrusion from various security problems, such as
                        viruses that threaten the security of the User’s
                        terminal device information and data and then affect the
                        normal use of the Service. The Service Provider shall
                        not be held liable in the event that the Shelly Service
                        is temporarily or permanently not available, as well as
                        for any loss and/or damages suffered by the User due to
                        hacker attacks, harmful viruses and/or other external
                        interference beyond Service Provider’s control.
                    </li>
                    <li>
                        7.7 In case of any malfunctions of the Service, the
                        Service User may contact the Shelly Support Team at{' '}
                        <a href="mailto:support@infinno.com">
                            support@infinno.com
                        </a>{' '}
                        to obtain the necessary help and assistance to resolve
                        the problems which have occurred
                    </li>{' '}
                </ul>
            </section>
            <section>
                <h2>8. PERSONAL DATA</h2>
                <ul>
                    <li>
                        8.1 In the course of using the Service, grounds for
                        collection of personal data may arise. Before initiating
                        the use of the Service, the Service User is provided
                        with an easy and accessible way to get acquainted with
                        the applicable Privacy Policy in accordance with the
                        regulatory requirements, which includes information for
                        the types of personal data, the grounds and ways of
                        their collection, the data subject rights, arising in
                        connection with the collection of personal data, the
                        contact details of the Data Protection Officer; the
                        possible recipients, to whom the personal data can be
                        disclosed and other detailed information.
                    </li>
                    <li>
                        8.2 The Service User must use the Service in good faith
                        and not to use it in a way which might harm or put in
                        danger the rights and legitimate interests of third
                        parties. The Shelly Service are developed to guarantee
                        safety and security and are not intended for unlawful
                        purposes and misuse.
                    </li>
                    <li>
                        8.3 By using the Service under these General Terms and
                        Conditions the Service User who is not a Master Admin
                        User acknowledges to be aware that the data required for
                        the registration of his/her Account (such as e-mail,
                        phone number, name and User’s category), are provided to
                        the Service Provider by the owner of the Master Account
                        and are processed only for the purposes of the Service.
                    </li>
                </ul>
            </section>
            <section>
                <h2>9. INTELLECTUAL PROPERTY</h2>
                <ul>
                    <li>
                        9.1 The Service Provider has and retains all its rights
                        over the intellectual property on or related in some way
                        to the Shelly Devices and/or the Service, including but
                        not limited to its software, interface, content,
                        functionalities, logos, graphic images or inscriptions,
                        commercial symbols, dynamic symbols, texts and/or
                        multimedia content etc. irrespective of whether these
                        are its own or received by way of contractual licenses
                        or in any other manner.
                    </li>
                    <li>
                        9.2 All Shelly and Qubino trademarks, service mark,
                        trade names, logos, domain names, and any other features
                        of the Shelly brand (Shelly Brand Features) are sole
                        intellectual property of the Service Provider or the
                        Service Provider has all rights on their use. These
                        General Terms and Conditions do not grand the Service
                        User any rights to use any Shelly or Qubino Brand
                        Features, whether for commercial and non-commercial
                        purposes.
                    </li>
                    <li>
                        9.3 Nothing in these General Terms and Conditions shall
                        be deemed permission on the part of the Service Provider
                        to the Service User to reproduce, copy, decompile,
                        disassemble, reverse engineer, create derivative works
                        or modify in any other manner any part of the Service,
                        including by entering any external content thereto.
                        <ul>
                            <li>
                                9.3.1 The Shelly Cloud Service is licensed, not
                                sold or transferred to the Service User, and the
                                Service Provider retain ownership of all copies
                                of mobile applications and web interface
                                software even after installation on a Service
                                User Access mobile device or access via internet
                                browser.
                            </li>
                            <li>
                                9.3.2 The Service is dedicated only to personal
                                use аs per its intended purpose. The Service
                                User is not entitled to copy, transfer and/or
                                use the Service for commercial purposes. Each
                                misuse of the Service for other purposes, other
                                than those explicitly permitted under these
                                General Terms and Conditions is prohibited and
                                shall be considered as a violation of these
                                General Terms and Conditions and a violation of
                                the intellectual property rights of the Service
                                Provider.
                            </li>
                            <li>
                                9.3.3 In case of violation by the Service User
                                of the above-mentioned the Service Provider
                                shall have the right to suspend any User
                                Account, cease the Service or specific
                                functionalities thereof and seek indemnification
                                for the suffered thereof damages and losses.
                            </li>
                        </ul>
                    </li>
                </ul>
            </section>
            <section>
                <h2>10. MISCELLANEOUS</h2>
                <ul>
                    <li>
                        10.1 These Terms and Conditions may be amended or
                        modified unilaterally by Service Provider at any time at
                        its sole discretion or if the amendments have been
                        imposed by virtue of legal acts entered into force, and
                        without an explicit prior notification to Service Users.
                        Such amendments shall be effective immediately and shall
                        be binding for the Service Users as of the moment these
                        were communicated to the Service User by any means,
                        including within the Account. Amendments may have
                        retroactive effect. Service User, on his/her
                        responsibility should read these Terms and Conditions
                        regularly and familiarize himself/herself with the
                        amendments (if any). The continued use of the Service,
                        its features and functionalities following the
                        implementation of amendments will constitute the binding
                        acceptance of those amendments by the Service User.
                    </li>
                    <li>
                        10.2 All disputes arising out of the implementation of
                        or in connection with these Terms and Conditions shall
                        be settled by mutual negotiation. In event of frailer to
                        reach a mutual agreement by negotiation, for Service
                        Users, who are EU citizens or have habitual residence in
                        EU, you enjoy the protection afforded to you by
                        mandatory provisions of the law of your country of
                        residence, and may bring a claim to enforce your rights
                        at the court in the EU country in which you live or to
                        enforce your consumer protection right via Online
                        Dispute Resolution, available at ec.europa.eu.
                    </li>
                </ul>
            </section>
            <p className='right-reserved'>
                <strong>All rights reserved! Shelly Europe Ltd.</strong>
            </p>
        </div>
    );
}
