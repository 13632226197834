import { createSlice } from '@reduxjs/toolkit';

const loginEmail = createSlice({
    name: 'loginEmail',
    initialState: null,
    reducers: {
        setEmail: (_state, { payload }) => {
            return payload;
        },
    },
});

export const { setEmail } = loginEmail.actions;

export const selectLoginEmail = state => state.loginEmail;

export default loginEmail.reducer;
