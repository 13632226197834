import Stack from '@mui/system/Stack';
import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './NotFound.scss';

export default function NotFound({ withHeaderAndFooter }) {
    return (
        <div className="not-found-wrapper">
            {withHeaderAndFooter && <Header />}
            <div className='main'>
                <Stack
                    sx={{ height: '100%' }}
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <h4 style={{ fontSize: '3rem' }}>Page not found</h4>
                </Stack>
            </div>
            {withHeaderAndFooter && <Footer />}
        </div>
    );
}
