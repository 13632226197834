import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';

import { useForm } from 'react-hook-form';
import Input from '../../components/Input/Input';
import { emailRegex, strings } from '../../utils/common';

import { useNavigate } from 'react-router-dom';
import { keycloakConfig } from '../../utils/configs';
import { useEffect, useRef, useState } from 'react';
import { checkEmailAuthServers } from '../../service/service';
import { useFeedbackContext } from '../../contexts/Feedback';

import './Login.scss';
import { useDispatch } from 'react-redux';
import { setEmail } from '../../redux/features/loginEmail';

export default function Login() {
    const navigate = useNavigate();
    const [serversData, setServersData] = useState(null);
    const [selectedServer, setSelectedServer] = useState('');
    // const [checkingForSettings, setChe]
    const dispatch = useDispatch();
    const nextBtnRef = useRef();
    const { showFeedback } = useFeedbackContext();

    const {
        register,
        formState: { errors },
        handleSubmit,
        getValues,
        setValue,
    } = useForm();

    useEffect(() => {
        const withTenant = window.location.href.includes('tenancy=false');

        if (withTenant) {
            const splitted = window.location.hash.split('email=');
            const email = splitted[splitted.length - 1];

            if(splitted.length >= 2) {
                setValue('email', email);
            }

            if (nextBtnRef?.current && emailRegex.test(email)) {
                // nextBtnRef.current.click();
            }
            return;
        }

        const authSettings = localStorage.getItem('authSettings');
        if (!!authSettings) {
            navigate('/dashboard');
        }

        console.log('authSettings', authSettings);
    }, [nextBtnRef]);

    const setAuthSettings = (settings, authType) => {
        console.log('settings', settings);
        localStorage.setItem(
            'authSettings',
            JSON.stringify({ settings, authType })
        );
        localStorage.setItem('logoutAuthSettings', JSON.stringify(settings));
    };

    const selectServerHandler = (id) => {
        const selected = serversData.find((e) => e.id === id);

        const authType = !!selected?.realm ? 'KEYCLOAK' : 'OTHER';
        setSelectedServer(id);
        setAuthSettings(selected, authType);
    };

    const nextHandler = () => {
        const { email } = getValues();
        dispatch(setEmail(email));
        navigate('/dashboard');
    };

    const submitHandler = async (data) => {
        try {
            const res = await checkEmailAuthServers(data);
            const withIds = res.map((e, i) => ({ ...e, id: i }));
            const settings = res[0];
            if (res.length === 1) {
                setAuthSettings(
                    settings,
                    !!settings?.realm ? 'KEYCLOAK' : 'OTHER'
                );
                nextHandler();
                return;
            }

            setServersData(withIds);
        } catch (err) {
            showFeedback(err.response?.data?.message);
        }
    };

    const asOwnerBtnHandler = () => {
        localStorage.setItem(
            'authSettings',
            JSON.stringify({ settings: keycloakConfig, authType: 'KEYCLOAK' })
        );
        navigate('/dashboard');
    };

    return (
        <Stack
            className="login-page-wrapper"
            justifyContent={'center'}
            alignItems={'center'}
        >
            <form className="login-form" onSubmit={handleSubmit(submitHandler)}>
                <Stack gap={'20px'}>
                    <div className="form-header">
                        <p>Login</p>
                    </div>
                    <Input
                        inputWrapperClassName="grow-1"
                        label="E-mail"
                        id={'email'}
                        placeholder={'Email'}
                        registerForm={() =>
                            register('email', {
                                required: strings.REQUIRED_VALIDATION_MESSAGE,
                                pattern: {
                                    value: emailRegex,
                                    message: strings.VALID_EMAIL,
                                },
                            })
                        }
                        hasError={errors?.email}
                        errorMsg={errors?.email?.message}
                    />

                    {serversData ? (
                        <>
                            <Stack>
                                <label className="label" id="name-label">
                                    Servers
                                </label>
                                <div className="select-wrapper">
                                    <Select
                                        fullWidth
                                        labelId="name-label"
                                        id="demo-multiple-name"
                                        onChange={(ev) =>
                                            selectServerHandler(ev.target.value)
                                        }
                                        input={<OutlinedInput />}
                                        value={selectedServer}
                                    >
                                        {serversData.map(({ name, id }) => (
                                            <MenuItem key={name} value={id}>
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </Stack>
                            <Stack gap={'20px'}>
                                <button
                                    className="btn-blue"
                                    onClick={nextHandler}
                                    disabled={
                                        !selectedServer && selectedServer !== 0
                                    }
                                >
                                    Next
                                </button>
                                {/* <button
                                    className="btn-blue"
                                    onClick={() => setServersData(null)}
                                >
                                    Back
                                </button> */}
                            </Stack>
                        </>
                    ) : (
                        <>
                            <button
                                ref={nextBtnRef}
                                className="btn-blue"
                                type="submit"
                            >
                                Next
                            </button>
                            {/* <button
                                className="btn-blue"
                                onClick={asOwnerBtnHandler}
                            >
                                as owner of Setup
                            </button> */}
                        </>
                    )}
                </Stack>
            </form>
        </Stack>
    );
}
