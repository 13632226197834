import React, { createContext, useContext, useState } from 'react';

const context = createContext();

export default function FeedbackContextProvider({ children }) {
    const [feedbackConfig, setFeedbackConfig] = useState({
        open: false,
        message: '',
        onClose: hideFeedback,
        autoHideDuration: 6000000,
    });
    
    const showFeedback = (message) =>
        setFeedbackConfig((oldState) => ({ ...oldState, message, open: true }));
    function hideFeedback() {
        setFeedbackConfig((oldState) => ({ ...oldState, open: false }));
    }

    return (
        <context.Provider value={{ feedbackConfig, showFeedback, hideFeedback }}>
            {children}
        </context.Provider>
    );
}

export const useFeedbackContext = () => useContext(context);
