import { Dialog, Tooltip } from '@mui/material';
import Stack from '@mui/system/Stack';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { deactivateSetup } from '../../service/service';
import './SetupDetails.scss';
import Loader from '../../components/Loader/Loader';
import { useFeedbackContext } from '../../contexts/Feedback';
import AuthSettingsPopup from './AuthSettingsPopup/AuthSettingsPopup.jsx';
import { useDispatch } from 'react-redux';
import {
    openAddRemoveDomainsDialog,
    openAuthSettingsDialog,
} from '../../redux/features/setupDetailsState.js';
import AddRemoveDomains from './AddRemoveDomains/AddRemoveDomains.jsx';
import useGetSetupDetailsData from './hooks/useGetSetupDetailsData.jsx';
import SetupDetailsProvider from './contexts/SetupDetailsContext.jsx';
import NamePaySection from './NameSection/NameSection.jsx';
import {
    getDocumentsInfo,
    getPayments,
    getSubscription,
} from '../../service/billingService.js';
import PaymentDetails from './PaymentDetails/PaymentDetails.jsx';
import PaymentTable from './PaymentTable/PaymentTable.jsx';
import { useAuth } from '../../authentication/authentication.jsx';
import { roles } from '../../utils/common.js';
import { jwtDecode } from 'jwt-decode';

export default function SetupDetails() {
    const { data, refetch, error, isLoading } = useGetSetupDetailsData();
    const [billingData, setBillingData] = useState(null);
    const [fetchingBillingData, setFetchingBillingData] = useState(true);
    const authConfig = data?.authConfig;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const redirectUrl = data?.bmsInstance?.frontUrl;
    const [deactivatePopupOpen, setDeactivatePopupOpen] = useState(false);
    const openPopup = () => setDeactivatePopupOpen(true);
    const closePopup = () => setDeactivatePopupOpen(false);
    const [billingDocuments, setBillingDocuments] = useState(null);
    const [paymentsData, setPaymentsData] = useState(null);
    const { showFeedback } = useFeedbackContext();
    const { role, token } = useAuth();
    const decoded = token && jwtDecode(token);

    const fetchBillingDocuments = async () => {
        try {
            const res = await getDocumentsInfo(data.externalClientId);
            setBillingDocuments(res);
        } catch (err) {
            console.log(err);
        }
    };

    const fetchPaymentsData = async () => {
        try {
            const res = await getPayments(1, 50, { email: decoded.email });
            setPaymentsData(res);
        } catch (err) {
            console.log(err);
        }
    };

    if (!billingDocuments && !!data) {
        fetchBillingDocuments();
    }

    if (!paymentsData) {
        fetchPaymentsData();
    }

    function loginSetupHandler() {
        if (!redirectUrl) {
            return;
        }
        window.location.href = redirectUrl;
    }

    const fetchSubscription = async () => {
        try {
            const subData = await getSubscription(data.subscriptionId);
            setBillingData(subData);
        } catch (err) {
            console.log(err);
        }

        setFetchingBillingData(false);
    };

    if (!!data && !billingData) {
        fetchSubscription();
    }

    if (
        error?.response?.data?.code === 'INVALID_TENANT_IDENTIFIER_OR_NO_ACCESS'
    ) {
        showFeedback(error?.response?.data?.message);
        navigate('/dashboard');
    }

    if (isLoading || fetchingBillingData) {
        return <Loader height={'300px'} />;
    }

    return (
        <SetupDetailsProvider
            setupData={data}
            billingData={billingData}
            paymentsData={paymentsData}
            billingDocuments={billingDocuments}
        >
            <section className="setup-details-section flex">
                <Stack
                    gap={'15px'}
                    justifyContent="space-around"
                    className="sections-wrapper"
                    flexDirection="row"
                >
                    {role !== roles.USER && <PaymentDetails />}
                    <Stack gap={'20px'} className="setup-wrapper">
                        <NamePaySection
                            name={data?.name}
                            refetch={refetch}
                            lastInvoice={
                                billingDocuments && billingDocuments[0]
                            }
                            clientId={data?.externalClientId}
                        />
                        <button
                            className="btn-blue-white"
                            onClick={loginSetupHandler}
                        >
                            Login the setup
                        </button>
                        <div>
                            <p className="section-title">Domain settings</p>
                            <button className="btn-blue-white">Activate</button>
                            {/* <DomainSettings /> */}
                        </div>
                        <div>
                            <p className="section-title">
                                Authentication and authorization
                            </p>
                            <button
                                className="btn-blue-white"
                                onClick={() =>
                                    dispatch(openAuthSettingsDialog())
                                }
                            >
                                Change AA server
                            </button>
                        </div>
                        {!authConfig?.main && (
                            <button
                                className="btn-blue-white"
                                onClick={() =>
                                    dispatch(openAddRemoveDomainsDialog())
                                }
                            >
                                Add/remove domains
                            </button>
                        )}

                        <div>
                            <p className="section-title">
                                Account deactivation
                            </p>
                            <button
                                onClick={openPopup}
                                className="btn-blue-white"
                            >
                                Deactivate account
                            </button>
                        </div>
                    </Stack>
                    {role !== roles.USER && <PaymentTable />}
                </Stack>
                <DeactivatePopup
                    open={deactivatePopupOpen}
                    onClose={closePopup}
                    setupName={data?.name}
                    refetch={refetch}
                />
                <AuthSettingsPopup authConfig={authConfig} refetch={refetch} />
                <AddRemoveDomains authConfig={authConfig} />
            </section>
        </SetupDetailsProvider>
    );
}

function DeactivatePopup({ open, onClose, setupName }) {
    const { id } = useParams();
    const navigate = useNavigate();
    const { showFeedback } = useFeedbackContext();
    const [isLoading, setIsLoading] = useState(false);

    const onConfirm = async () => {
        setIsLoading(true);

        try {
            await deactivateSetup(id);
            navigate('/dashboard');
        } catch (err) {
            console.log(err);
            showFeedback(err.response?.data?.message);
        }
        setIsLoading(false);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            className="deactivate-setup-popup"
        >
            <Stack sx={{ padding: '10px', maxWidth: '250px' }} gap="10px">
                <p>
                    Account deactivation will delete all your data from your{' '}
                    {setupName} setup. Are you sure?
                </p>
                <Stack flexDirection={'row'} justifyContent="end" gap="10px">
                    <button
                        className="cancel-btn"
                        onClick={onClose}
                        disabled={isLoading}
                    >
                        Cancel
                    </button>
                    <button
                        className="delete-btn"
                        onClick={onConfirm}
                        disabled={isLoading}
                    >
                        Delete
                    </button>
                </Stack>
            </Stack>
        </Dialog>
    );
}
