import './DownloadApp.scss';

export default function DownloadApp() {
    return (
        <section className="download-app-section flex" id="get-the-app">
            <div className="content">
                <h3>Download App</h3>
                <p className="description">
                    Experience control at your fingertips with our Access
                    control app:
                </p>
                <div className="links-wrapper flex">
                    <a
                        target="_blank"
                        rel="noreferrer"
                        className="app-link flex"
                        href="https://apps.apple.com/bg/app/access-control-by-shelly/id6474441948"
                    >
                        <img src="./images/apple-badge.svg" alt="apple badge" />
                    </a>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://play.google.com/store/apps/details?id=com.shelly.AccessControl"
                        className="app-link flex"
                    >
                        <img
                            src="./images/google-play-badge.png"
                            alt="google play badge"
                        />
                    </a>
                </div>
            </div>
            <div className="images-wrapper">
                <div>
                    <img
                        src="./images/Group 1000004724.png"
                        alt="smartphones"
                    />
                </div>
            </div>
        </section>
    );
}
