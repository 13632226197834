import './Input.scss';

export default function Input({
    label,
    labelClass = '',
    id,
    disabled = false,
    placeholder,
    registerForm,
    hasError = false,
    errorMsg,
    inputWrapperClassName = '',
}) {
    return (
        <div className={`input-wrapper flex ${inputWrapperClassName}`}>
            <label className={labelClass} htmlFor={id}>
                {label}
            </label>
            <input
                className="input"
                disabled={disabled}
                placeholder={placeholder}
                id={id}
                {...registerForm()}
            />
            {hasError && <p className="error-msg">{errorMsg}</p>}
        </div>
    );
}
