import { useParams } from 'react-router-dom';
import { getSetupById } from '../../../service/service';
import { reactQuerySettings } from '../../../utils/common';
import { useQuery } from 'react-query';

export default function useGetSetupDetailsData() {
    const { id } = useParams();
    const { data, refetch, error, isLoading, isFetching } = useQuery(
        `${id}-setup-data`,
        () => getSetupById(id),
        {
            ...reactQuerySettings,
            refetchOnMount: true,
        }
    );
    return {data, refetch, error, isLoading, isFetching}
}
