//accept current when merge
export const apiBaseUrl = 'https://accesscontrol.shelly.com/backend';
export const deployClientUrl = 'https://d.infn.dev/orchestrator-front';

export const clientUrl =
    process.env.NODE_ENV === 'development'
        ? 'http://localhost:3000/#'
        : 'https://d.infn.dev/orchestrator-front/#';
export const prodClientUrl = 'https://accesscontrol.shelly.com/#';

export const logoutRedirectUrl =
    process.env.NODE_ENV === 'development'
        ? 'http://localhost:3000/'
        : //accept current when merge
          'https://accesscontrol.shelly.com/';
          
export const billingBackendUrl = 'https://bms-billing.infn.dev/backend/';

export const endpoints = {
    REGISTER: '/register',
    ADD_TENANT: '/api/user/tenants/add',
    REDIRECT: '/redirect',
    GET_TENANTS: '/api/user/tenants',
    GET_SETUP_DETAILS: '/api/user/tenants',
    UPDATE_SETTING_NAME: '/api/user/tenants',
    DEACTIVATE_SETUP: '/api/user/tenants',
    SEND_REQUEST_EMAIL: '/email-inquiry',
    UPDATE_AUTH_SETTINGS: (tenantId) =>
        `/api/user/tenants/${tenantId}/updateAuth`,
    GET_AUTH_CONFIG_DOMAINS: (authConfigId) =>
        `api/auth-configs/${authConfigId}/email-domains`,
    ADD_AUTH_CONFIG_DOMAIN: (authConfigId) =>
        `api/auth-configs/${authConfigId}/email-domains/add`,
    DELETE_AUTH_CONFIG_DOMAIN: (authConfigId, emailDomainId) =>
        `api/auth-configs/${authConfigId}/email-domains/${emailDomainId}`,
    CHECK_EMAIL_AUTH_SERVERS: 'email/check',
    GET_SUBSCRIPTION: (subscriptionId) =>
        `api/subscription?subscriptionId=${subscriptionId}`,
    GET_CLIENT_DOCUMENTS_INFO: (clientId) =>
        `client-document-info?clientId=${clientId}`,
    DOWNLOAD_INVOICE: (clientId, orderId) =>
        `api/client/get-invoice?clientId=${clientId}&orderId=${orderId}`,
    GET_PAYMENTS: (page, size) => `api/payments?page=${page}&pageSize=${size}`,
    GET_CONFIG_ID: 'api/payment/config'
};
