import React from 'react';
import withHeaderAndFooter from '../../components/HOCS/WithHeaderAndFooter';

import './Contacts.scss';

const Contacts = () => {
    return (
        <div className="contacts-page-wrapper">
            <section className="content-wrapper">
                <div>
                    <h2>Dear Customer,</h2>
                </div>
                <p>
                    Please take notice that customer support is provided{' '}
                    <span className="bold">only in English.</span>
                </p>
                <a
                    className="text-blue"
                    href="mailto:support.accesscontrol@shelly.cloud"
                >
                    support.accesscontrol@shelly.cloud
                </a>
                <h2>Corporate contacts</h2>
                <p className="bold">SHELLY EUROPE LTD.</p>
                <p>103 Cherni Vrah Blvd Bulgaria, Sofia 1407</p>
                <h2>Don't hesitate to reach us!</h2>
            </section>
        </div>
    );
};

const Wrapped = withHeaderAndFooter(Contacts);

export default Wrapped;
