import { configureStore } from '@reduxjs/toolkit';
import landingPageStateReducer from '../features/landingPageState';
import setupDetailsSlice from '../features/setupDetailsState';
import userDataSlice from '../features/userData';
import loginEmailSlice from '../features/loginEmail';

export const store = configureStore({
    reducer: {
        landingPageState: landingPageStateReducer,
        setupDetailsState: setupDetailsSlice,
        userData: userDataSlice,
        loginEmail: loginEmailSlice,
    },
});
