import {
    Dialog,
    DialogTitle,
    Divider,
    IconButton,
    MenuItem,
    Select,
    Stack,
} from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    closeAuthSettingsDialog,
    selectAuthSettingsDialogOpen,
} from '../../../redux/features/setupDetailsState';
import Input from '../../../components/Input/Input';
import { useForm } from 'react-hook-form';
import { emailRegex, strings } from '../../../utils/common';
import { useAuth } from '../../../authentication/authentication';
import { updateAuthSettings } from '../../../service/service';
import { useParams } from 'react-router-dom';
import { useFeedbackContext } from '../../../contexts/Feedback';

export default function AuthSettingsPopup({ authConfig, refetch }) {
    const open = useSelector(selectAuthSettingsDialogOpen);
    const { id } = useParams();
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({ defaultValues: authConfig.main ? {} : authConfig });

    const dispatch = useDispatch();

    const closeHandler = () => {
        dispatch(closeAuthSettingsDialog());
        reset({ keepErrors: false });
    };
    const { showFeedback } = useFeedbackContext();

    const submitHandler = async (data) => {
        const authConfigSubmitData = { ...data };
        delete authConfigSubmitData.email;
        delete authConfigSubmitData.id;
        delete authConfigSubmitData.main;
        delete authConfigSubmitData.redirectUrl;

        try {
            await updateAuthSettings(id, {
                authConfig: authConfigSubmitData,
                email: data.email,
            });
            showFeedback(strings.AUTH_SETTING_CHANGE_SUCCESS);
            refetch()
            closeHandler();
        } catch (err) {
            showFeedback(err.response?.data?.message);
        }
    };

    return (
        <Dialog
            open={open}
            className="auth-setting-popup"
            onClose={closeHandler}
        >
            <div style={{ padding: '10px' }}>
                <DialogTitle>AA Settings</DialogTitle>
                <form onSubmit={handleSubmit(submitHandler)}>
                    <Stack gap={'15px'}>
                        <Input
                            label="Name"
                            id={'name'}
                            placeholder={'Name'}
                            registerForm={() =>
                                register('name', {
                                    required:
                                        strings.REQUIRED_VALIDATION_MESSAGE,
                                })
                            }
                            hasError={errors?.name}
                            errorMsg={errors?.name?.message}
                        />
                        <Input
                            label="Client ID"
                            id={'clientId'}
                            placeholder={'Client ID'}
                            registerForm={() =>
                                register('clientId', {
                                    required:
                                        strings.REQUIRED_VALIDATION_MESSAGE,
                                })
                            }
                            hasError={errors?.clientId}
                            errorMsg={errors?.clientId?.message}
                        />
                        <Input
                            label="Client secret"
                            id={'clientSecret'}
                            placeholder={'Client secret'}
                            registerForm={() => register('clientSecret')}
                        />
                        <Input
                            label="Realm"
                            id={'realm'}
                            placeholder={'Realm'}
                            registerForm={() => register('realm')}
                        />
                        <Input
                            label="Base URL"
                            id={'baseUrl'}
                            placeholder={'Base URL'}
                            registerForm={() =>
                                register('baseUrl', {
                                    required:
                                        strings.REQUIRED_VALIDATION_MESSAGE,
                                })
                            }
                            hasError={errors?.baseUrl}
                            errorMsg={errors?.baseUrl?.message}
                        />
                        <Input
                            label="Scope"
                            id={'scope'}
                            placeholder={'Scope'}
                            registerForm={() =>
                                register('scope', {
                                    required:
                                        strings.REQUIRED_VALIDATION_MESSAGE,
                                })
                            }
                            hasError={errors?.scope}
                            errorMsg={errors?.scope?.message}
                        />
                        <Input
                            label="Issuer"
                            id={'issuer'}
                            placeholder={'Issuer'}
                            registerForm={() =>
                                register('issuer', {
                                    required:
                                        strings.REQUIRED_VALIDATION_MESSAGE,
                                })
                            }
                            hasError={errors?.issuer}
                            errorMsg={errors?.issuer?.message}
                        />
                        <Input
                            label="JWKS URI"
                            id={'jwksUri'}
                            placeholder={'JWKS URI'}
                            registerForm={() =>
                                register('jwksUri', {
                                    required:
                                        strings.REQUIRED_VALIDATION_MESSAGE,
                                })
                            }
                            hasError={errors?.jwksUri}
                            errorMsg={errors?.jwksUri?.message}
                        />
                        <Stack className="input-wrapper">
                            <label className="label" htmlFor="type">
                                Type
                            </label>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="type"
                                variant="standard"
                                defaultValue={
                                    authConfig.main ? '' : authConfig?.type
                                }
                                {...register('type', {
                                    required:
                                        strings.REQUIRED_VALIDATION_MESSAGE,
                                })}
                            >
                                <MenuItem value={'JWT'}>JWT</MenuItem>
                                <MenuItem value={'AT_JWT '}>AT_JWT </MenuItem>
                            </Select>
                            {!!errors?.type && (
                                <p className="error-msg">
                                    {errors?.type?.message}
                                </p>
                            )}
                        </Stack>
                        <div>
                            <p>
                                Please provide an email for authentication as
                                Master Administrator in your setup. The email
                                should be in the proper domain.
                            </p>
                            <Input
                                label=""
                                id={'email'}
                                placeholder={'Email'}
                                registerForm={() =>
                                    register('email', {
                                        required:
                                            strings.REQUIRED_VALIDATION_MESSAGE,
                                        pattern: {
                                            value: emailRegex,
                                            message: strings.VALID_EMAIL,
                                        },
                                    })
                                }
                                hasError={errors?.email}
                                errorMsg={errors?.email?.message}
                            />
                        </div>
                        <Stack
                            flexDirection={'row'}
                            gap={'10px'}
                            sx={{ marginTop: '10px' }}
                        >
                            <button
                                onClick={closeHandler}
                                className="red-form-btn"
                                type="reset"
                            >
                                Cancel
                            </button>
                            <button
                                // disabled={isLoading}
                                className="form-btn"
                                type="submit"
                            >
                                Submit
                            </button>
                        </Stack>
                    </Stack>
                </form>
            </div>
        </Dialog>
    );
}
