import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    authSettingsDialogOpen: false,
    addRemoveDomainsDialogOpen: false,
    setupDetails: null,
};

const setupDetailsSlice = createSlice({
    name: 'setupDetailsState',
    initialState,
    reducers: {
        openAuthSettingsDialog: (state) => {
            state.authSettingsDialogOpen = true;
        },
        closeAuthSettingsDialog: (state) => {
            state.authSettingsDialogOpen = false;
        },
        openAddRemoveDomainsDialog: (state) => {
            state.addRemoveDomainsDialogOpen = true;
        },
        closeAddRemoveDomainsDialog: (state) => {
            state.addRemoveDomainsDialogOpen = false;
        },
    },
});

export default setupDetailsSlice.reducer;

export const {
    openAuthSettingsDialog,
    closeAuthSettingsDialog,
    openAddRemoveDomainsDialog,
    closeAddRemoveDomainsDialog,
} = setupDetailsSlice.actions;

export const selectAuthSettingsDialogOpen = (state) =>
    state.setupDetailsState.authSettingsDialogOpen;

export const selectAddRemoveDomainsDialogOpen = (state) =>
    state.setupDetailsState.addRemoveDomainsDialogOpen;
