import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import {
    openRegisterPopup,
    openRequestPopup,
    setSelectedCard,
    setSelectedPlan,
} from '../../../../redux/features/landingPageState';
import './Card.scss';

export default function Card({
    title,
    options,
    price,
    planType,
    subTitle,
    button,
}) {
    const selectedPlanCard = useSelector(
        (state) => state.landingPageState?.selectedPlanCard
    );
    const isSelected = selectedPlanCard === planType;
    const dispatch = useDispatch();

    function btnHandler(ev) {
        ev.stopPropagation();
        dispatch(setSelectedPlan(planType));
        dispatch(setSelectedCard(planType));

        if (button.type === 'plan') {
            dispatch(openRegisterPopup());  
        } else {
            dispatch(openRequestPopup());
        }
    }

    return (
        <ClickAwayListener onClickAway={() => dispatch(setSelectedCard(''))}>
            <div
                className={
                    'plan-card-wrapper' +
                    (isSelected
                        ? ' selected-card-wrapper'
                        : '')
                }
                onClick={(ev) => {
                    ev.stopPropagation();
                    dispatch(setSelectedCard(planType));
                }}
                onDoubleClick={btnHandler}
            >
                <div className="card-content">
                    <h4>{title}</h4>
                    <div className="card-price-section">
                        {price && <p>€{Number(price).toFixed(2)}</p>}
                        <p>{subTitle}</p>
                    </div>
                    <ul>
                        {options?.map((o, i) => (
                            <span key={i} className="plan-li-wrapper flex">
                                <span className="li-check">
                                    {' '}
                                    <img
                                        src="./images/check.png"
                                        alt="check-icon"
                                    />
                                </span>{' '}
                                <li>{o}</li>
                            </span>
                        ))}
                    </ul>
                </div>
                <button
                    onClick={btnHandler}
                    className={isSelected ? "choose-plan-btn btn-white" : "choose-plan-btn btn-blue-white"}
                >
                    {button.label}
                </button>
            </div>
        </ClickAwayListener>
    );
}
