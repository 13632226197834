import axios from 'axios';
import { billingBackendUrl, endpoints } from './urls';
import { axiosRedirectInstance } from '../axios/config';

//TODO setup billing axios interceptors

export const getSubscription = async (subscriptionId) => {
    const token = localStorage.getItem('token');

    const res = await axiosRedirectInstance.get(
        endpoints.GET_SUBSCRIPTION(subscriptionId),
        { headers: { Authorization: `Bearer ${token}` } }
    );
    return res.data;
};

export const getDocumentsInfo = async (clientId) => {
    const token = localStorage.getItem('token');

    const res = await axiosRedirectInstance.get(
        endpoints.GET_CLIENT_DOCUMENTS_INFO(clientId),
        { headers: { Authorization: `Bearer ${token}` } }
    );
    return res.data;
};

export const downloadInvoice = async (clientId, orderId) => {
    const token = localStorage.getItem('token');

    return await axiosRedirectInstance.get(
        endpoints.DOWNLOAD_INVOICE(clientId, orderId),
        { headers: { Authorization: `Bearer ${token}`, responseType: 'blob' } }
    );
};

export const getPayments = async (page, size, body) => {
    const res = await axiosRedirectInstance.post(
        endpoints.GET_PAYMENTS(page, size),
        body
    );

    return res.data;
};

export const getConfigId = async (config) => {
    const res = await axiosRedirectInstance.post(
        endpoints.GET_CONFIG_ID,
        config
    );

    return res.data;
};
