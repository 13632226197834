import './RolesAndAccess.scss';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectOpenedRolesPopover,
    setOpenedRolesPopover,
} from '../../../redux/features/landingPageState';
import MyPopover from '../../../components/Popover/Popover';

export default function RolesAndAccess() {
    return (
        <section className="roles-and-access" id="access">
            <h3 className="section-title">Roles and Access</h3>
            <p>
                In the Shelly access control, we cater to a variety of user
                roles to meet diverse <br /> needs. These roles include:
            </p>
            <div className="cards-wrapper flex">
                <div className="cards-group">
                    <Card
                        horizontal
                        title="Master Administrator"
                        icon="master-administrator.svg"
                        description={
                            'Master Administrator is the role of each owner of Environment /the profile who register the setup/ through this page. The Master is the only person who can change the name, upgrade, downgrade, or deactivate the setup. This is the person who is in charge os. '
                        }
                    />
                    <Card
                        horizontal
                        title="Administrator"
                        icon="administrator.svg"
                        description={
                            "In addition to the technician's capabilities, the Administrator's role can also create, configure, and delete users, groups, zones, and access points. An Administrator can be created and deleted by a Master Administrator or another Administrator. The deletion can only be done after changing the Administrator's role to that of a User. "
                        }
                    />
                    <Card
                        horizontal
                        title="Group Administrator"
                        icon="group-administrator.svg"
                        description="Is an abstract, not system role, that grants the user the authority to act as
an Administrator but is limited only to the group. Group Administrators can create and add users to the group, configure
their IDs, add zones to the group, and access the web interface."
                    />
                    <Card
                        horizontal
                        title="Technician"
                        icon="technician.svg"
                        description="In addition to the regular user capabilities, can also add devices,
access and unlock them remotely, and view all points in the system, but does not have access to the web interface. This role also provides access through points in an ARMED state when the controller is online."
                    />
                    <Card
                        horizontal
                        title="User"
                        icon="user.svg"
                        description="A role that guarantees access to the points in the zones allowed for the groups in which the user is included. The User can manage his profile, change his password, get logs of
his activity, etc., without access to the Web portal."
                    />
                </div>
                <Card
                    title="Technician:"
                    description="In addition to the regular user capabilities, can also add devices,
access and unlock them remotely, and view all points in the system, but does not have access to the web interface. This role also provides access through points in an ARMED state when the controller is online."
                    icon="technician.svg"
                />
                <Card
                    title="User:"
                    description="A role that guarantees access to the points in the zones allowed for the groups in which the user is included. The User can manage his profile, change his password, get logs of
his activity, etc., without access to the Web portal."
                    icon="user.svg"
                />
            </div>
            <p id="managing" />
        </section>
    );
}

function Card({ title, description, icon, horizontal = false }) {
    const openedPopover = useSelector(selectOpenedRolesPopover);
    const closePopover = () => dispatch(setOpenedRolesPopover(null));
    const dispatch = useDispatch();
    const open = openedPopover === title;
    const [initalScrollPosition, setInitialScrollPosition] = useState(0);
    const [scrollPosition, setScrollPosition] = useState(0);
    const distanceToSection = Math.abs(scrollPosition - initalScrollPosition);

    const showPopoverHandler = (ev) => {
        if (!horizontal) {
            return;
        }

        if (!open) {
            ev.stopPropagation();
        }
        dispatch(setOpenedRolesPopover(title));
        setInitialScrollPosition(window.scrollY);
    };

    const isWithinSection = distanceToSection <= 450;

    useEffect(() => {
        if (!isWithinSection && open) {
            closePopover();
        }
    }, [distanceToSection]);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            setScrollPosition(window.scrollY);
        });
    }, []);

    return (
        <div
            // onMouseEnter={showPopoverHandler}
            className={
                'roles-card-wrapper' + (horizontal ? ' horizontal-wrapper' : '')
            }
            onMouseEnter={showPopoverHandler}
            onMouseLeave={closePopover}
        >
            <div className={'roles-card' + (horizontal ? ' horizontal' : '')}>
                <div className={`roles-card-img-wrapper ${icon.split('.')[0]}`}>
                    <img
                        src={`./images/icons/rolesAndAccess/${icon}`}
                        alt="icon"
                    />
                </div>
                <h4>{title}</h4>
                {!horizontal ? <p>{description}</p> : null}
            </div>
            {horizontal && <MyPopover open={open} content={description} />}
        </div>
    );
}
