import React from 'react';
import { bmsTenantsRedirect } from '../../utils/bmsTenantRedirect';
import SetupCard from '../../components/SetupCard/SetupCard';

export default function BmsTenants({ tenants }) {

    return (
        <div className="bms-tenants flex">
            {' '}
            {tenants.map((t) => {
                return (
                    <div
                        onClick={(ev) => {
                            ev.stopPropagation();
                            bmsTenantsRedirect(t.url);
                        }}
                    >
                        <SetupCard name={t.name} />
                    </div>
                );
            })}
        </div>
    );
}
