import { createSlice } from '@reduxjs/toolkit';

const landingPageStateSlice = createSlice({
    name: 'landingPageState',
    initialState: {
        registerPopupOpen: false,
        selectedPlan: 'user',
        selectedPlanCard: '',
        requestPopupOpen: false,
        contactUsPopupOpen: false,
        openedRolesPopover: null,
    },
    reducers: {
        openRegisterPopup: (state) => ({ ...state, registerPopupOpen: true }),
        closeRegisterPopup: (state) => ({ ...state, registerPopupOpen: false }),
        openRequestPopup: (state) => ({ ...state, requestPopupOpen: true }),
        closeRequestPopup: (state) => ({ ...state, requestPopupOpen: false }),
        openContactUsPopup: (state) => ({ ...state, contactUsPopupOpen: true }),
        closeContactUsPopup: (state) => ({
            ...state,
            contactUsPopupOpen: false,
        }),
        setSelectedPlan: (state, action) => ({
            ...state,
            selectedPlan: action.payload,
        }),
        setSelectedCard: (state, action) => ({
            ...state,
            selectedPlanCard: action.payload,
        }),
        setOpenedRolesPopover: (state, action) => ({
            ...state,
            openedRolesPopover: action.payload,
        }),
    },
});

export const {
    openRegisterPopup,
    closeRegisterPopup,
    setSelectedPlan,
    setSelectedCard,
    openRequestPopup,
    closeRequestPopup,
    openContactUsPopup,
    closeContactUsPopup,
    setOpenedRolesPopover
} = landingPageStateSlice.actions;

export default landingPageStateSlice.reducer;

export const selectLandingPageState = (state) => state.landingPageState;
export const selectOpenedRolesPopover = state => state.landingPageState.openedRolesPopover;