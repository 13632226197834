import { keycloakConfig } from "./configs";

export const bmsTenantsRedirect = (url) => {
    // localStorage.setItem(
    //     'authSettings',
    //     JSON.stringify({
    //         settings: keycloakConfig,
    //         authType: 'KEYCLOAK',
    //     })
    // );
    
    window.location.href = url;

};
