import {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import Keycloak from 'keycloak-js';
import * as tokensStorage from './tokensStorage';
import { jwtDecode } from 'jwt-decode';
import { stringify as stringifyQuery } from 'qs';
import { useSelector } from 'react-redux';
import { selectLoginEmail } from '../redux/features/loginEmail';

export const authContext = createContext({
    init: () => {},
    token: null,
    tokenParsed: null,
    isAuthStatusChecked: false,
    login: () => {},
    logout: () => {},
    resetPassword: async () => ({ isOk: true }),
    register: async () => ({ isOk: true }),
    changePassword: async () => ({ isOk: true }),
    error: null,
});

export const authTypes = {
    KEYCLOAK: 'KEYCLOAK',
    OTHER: 'OTHER',
};

function useKeycloakAuth(authSettings) {
    const [keycloak, setKeycloak] = useState();
    const [error, setError] = useState(null);
    const interval = useRef();
    const tokenParsed = keycloak && jwtDecode(keycloak?.token);
    const idToken = keycloak?.idToken;
    const roles = tokenParsed?.roles;
    const loginHint = useSelector(selectLoginEmail);
    
    useEffect(() => {
        if (idToken) {
            localStorage.setItem('idToken', idToken);
            localStorage.setItem('token', keycloak?.token);
        }
    }, [idToken]);

    const role =
        (roles?.includes('USER') && 'USER') ||
        (roles?.includes('PREMIUM_USER') && 'PREMIUM_USER') ||
        (roles?.includes('INTEGRATOR') && 'INTEGRATOR') ||
        (roles?.includes('CORPORATE') && 'CORPORATE');

    function clearTokenInterval() {
        interval.current && clearInterval(interval.current);
    }

    async function initKeycloak() {
        const keycloakSettings = {
            url: authSettings.baseUrl,
            clientId: authSettings.clientId,
            realm: authSettings.realm,
        };

        if (authSettings.scope) {
            keycloakSettings.scope = authSettings.scope;
        }

        const keycloak = new Keycloak(keycloakSettings);

        keycloak.responseMode = 'query';
        keycloak.redirectUri = authSettings.redirectUri;

        clearTokenInterval();

        interval.current = setInterval(() => {
            // console.log('Check token');
            try {
                keycloak.updateToken(60).catch((error) => {
                    // console.error("Unhandled promise error when updating token: ", error)
                });
            } catch (e) {
                console.error('Failed to update token: ', e);
                setError(e);
            }
        }, 10000);

        try {
            const isAuthenticated = await keycloak.init({
                onLoad: 'check-sso',
            });

            if (!isAuthenticated) {
                keycloak.login({
                    loginHint,
                });
                return;
            }
            console.log('isAuthenticated', isAuthenticated);
        } catch (e) {
            console.log('Failed to init keycloak: ', e);
            setError(e);
        }

        setKeycloak(keycloak);
        //setIsAuthStatusChecked(true)
    }

    function login() {
        if (error) {
            console.error(
                'Automatic login prevented because of the login error'
            );
            return;
        }
        // window.location.hash = ""

        function checkEncodeURI(str) {
            // eslint-disable-next-line
            return /\%/i.test(str);
        }

        if (checkEncodeURI(authSettings.scope)) {
            keycloak?.login({ scope: decodeURI(authSettings.scope) });
        } else {
            keycloak?.login({ scope: authSettings.scope });
        }
        // setTimeout(keycloak?.login, 10000)
    }

    useEffect(() => clearTokenInterval, []);

    //console.log(keycloak)

    return {
        init: initKeycloak,
        token: keycloak?.token,
        tokenParsed: keycloak?.tokenParsed,
        isAuthStatusChecked: !!keycloak,
        login,
        logout: keycloak?.logout,
        resetPassword: async () => ({ isOk: true }), // To do
        register: keycloak?.register,
        changePassword: async () => ({ isOk: true }),
        error,
        keycloak,
        role,
        updateToken: keycloak?.updateToken,
    };
}

const msInMinute = 60 * 1e3;

function useAlternativeAuth(authSettings) {
    const [isAuthStatusChecked, setIsAuthStatusChecked] = useState(false);
    const [user, setUser] = useState(null);
    const refreshTimeout = useRef();
    const userRef = useRef();
    userRef.current = user;

    function updateRefreshTimer(tokenParsed) {
        if (!tokenParsed?.exp) {
            return;
        }

        const exp = tokenParsed?.exp;
        const timeLeftMs = exp * 1000 - new Date().getTime();

        const secondsLeft = Math.floor(timeLeftMs / 1000);
        const minutesLeft = Math.floor(secondsLeft / 60);
        const hoursLeft = Math.floor(minutesLeft / 60);

        console.log(
            `Time before the token expires (hours:minutes:seconds): ${hoursLeft}:${
                minutesLeft % 60
            }:${secondsLeft % 60}`
        );

        if (refreshTimeout.current) {
            clearTimeout(refreshTimeout.current);
        }

        refreshTimeout.current = setTimeout(
            () => refreshToken(userRef.current?.refreshToken),
            timeLeftMs - 5 * 1000
        );
    }

    function setUserFromTokenStorage() {
        const token = tokensStorage.getAccessToken();

        console.log(
            'setUserFromTokenStorage: ',
            tokensStorage,
            'token: ',
            token
        );

        if (!token) {
            return false;
        }

        try {
            const tokenParsed = jwtDecode(token);

            updateRefreshTimer(tokenParsed);
            setUser({
                token,
                idToken: tokensStorage.getIdToken(),
                refreshToken: tokensStorage.getRefreshToken(),
                tokenParsed,
            });

            return true;
        } catch (e) {
            console.error('Error while parsing JWT from storage: ', e);
        }

        return false;
    }

    async function handleCode() {
        console.log('Handle code');

        const code = window.location.toString().split('code=')[1].split('&')[0];

        const details = {
            code,
            grant_type: 'authorization_code',
            client_id: authSettings.clientId,
            redirect_uri: authSettings.redirectUri,
        };

        const body = stringifyQuery(details);

        const tokenUrl = `${authSettings.baseUrl}/token`;

        const tokenResp = await fetch(`${tokenUrl}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body,
        });
        const newToken = await tokenResp.json();
        tokensStorage.setTokens(newToken);
    }

    async function init() {
        const currentUrl = window.location.href;

        console.log('Init, currentUrl: ', currentUrl);

        if (currentUrl.includes('code')) {
            await handleCode();
        }

        setUserFromTokenStorage();
        setIsAuthStatusChecked(true);
    }

    function login() {
        console.log('Redirect to login');
        const authUrl = `${authSettings.baseUrl}/authorize?response_type=code&client_id=${authSettings.clientId}&redirect_uri=${authSettings.redirectUri}&scope=${authSettings.scope}`;
        window.location.href = authUrl;
    }

    function logout() {
        const idToken = user.idToken;

        tokensStorage.clearTokens();
        window.location.href = `${authSettings.baseUrl}/endsession?id_token_hint=${idToken}&post_logout_redirect_uri=${authSettings.redirectUri}`;
    }

    async function refreshToken(refreshToken) {
        const body = {
            refresh_token: refreshToken,
            grant_type: 'refresh_token',
            client_id: authSettings.clientId,
            client_secret: authSettings.clientSecret,
            redirect_uri: authSettings.redirectUri,
        };

        const tokenUrl = `${authSettings.baseUrl}/token`;

        const tokenResp = await fetch(`${tokenUrl}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: stringifyQuery(body),
        });
        const newToken = await tokenResp.json();
        tokensStorage.setTokens(newToken);
        setUserFromTokenStorage();
    }

    return {
        init,
        ...(user || {}),
        isAuthStatusChecked,
        login,
        logout,
        resetPassword: async () => ({ isOk: true }), // To do
        register: async () => ({ isOk: true }), // To do
        changePassword: async () => ({ isOk: true }),
    };
}

function onBeforeUnload() {
    const withoutQuery = window.location.href.split('?');

    window.location.href = withoutQuery;
}

function useAuthHandler(authType, autoInit, settings) {
    const isInitialized = useRef(false);

    const keycloakAuth = useKeycloakAuth(settings);
    const alternativeAuth = useAlternativeAuth(settings);

    function initAuth() {
        if (isInitialized.current) {
            return;
        }
        isInitialized.current = true;

        window.addEventListener('beforeunload', onBeforeUnload);

        switch (authType) {
            case authTypes.KEYCLOAK:
                keycloakAuth.init();
                break;
            case authTypes.OTHER:
                alternativeAuth.init();
                break;
            default:
        }
    }

    useEffect(
        () => () => window.removeEventListener('beforeunload', onBeforeUnload),
        []
    );

    if (autoInit) {
        initAuth();
    }

    switch (authType) {
        case authTypes.KEYCLOAK:
            return {
                ...keycloakAuth,
                init: initAuth,
                settings,
            };
        case authTypes.OTHER:
            return {
                ...alternativeAuth,
                init: initAuth,
                settings,
            };
        default:
    }
}

export function AuthProvider({
    children,
    authType,
    autoInit = true,
    settings,
}) {
    const value = useAuthHandler(authType, autoInit, settings);

    // console.log("Auth procider render: ", value)

    return (
        <authContext.Provider value={value}>{children}</authContext.Provider>
    );
}

export const useAuth = () => useContext(authContext);
