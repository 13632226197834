import { useState, useEffect, useRef } from 'react';
import { splitText } from '../../../utils/functions';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

import './Ecosystem.scss';

function Card({
    title,
    description,
    img,
    className,
    setActiveImages,
    card,
    setUsersSelected,
}) {
    const splitDescription = splitText(description);

    return (
        <div className={`card-wrapper ${className} orientation`}>
            <div
                className="card-icon-wrapper"
                onMouseEnter={() => {
                    if (card === 'users') {
                        setUsersSelected(true);
                    }
                    setTimeout(() => {
                        setActiveImages(card);
                    }, [200]);
                }}
            >
                <img src={`./images/icons/${img}`} alt="icon" />
            </div>
            <div className="card-content" id={card}>
                <h5>{title}</h5>
                <p>{splitDescription || description}</p>
            </div>
        </div>
    );
}

const ImageCard = ({ src, index, hideImages, usersSelected, orientation, cssClass }) => {
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        const delays = {
            0: 100,
            1: 700,
            2: 1300,
        };
        setTimeout(() => {
            setHidden(false);
        }, delays[index]);
    }, []);
    let classes = `ecosystem-hover-image image-${index + 1} ${orientation} ${cssClass}`;

    if (hidden) {
        classes += ' hidden';
    }

    if (usersSelected) {
        classes += ' users-img';
    }

    return (
        <ClickAwayListener onClickAway={hideImages}>
            <img className={classes} src={src} alt="app" />
        </ClickAwayListener>
    );
};

export default function Ecosystem() {
    const [activeImages, setActiveImages] = useState(null);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 800);
    const [sectionPosition, setSectionPosition] = useState(0);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [usersSelected, setUsersSelected] = useState(false);
    const [initalScrollPosition, setInitialScrollPosition] = useState(0);

    useEffect(() => {
        const sectionElement = document.getElementById('ecosystem-section');
        if (!sectionPosition) {
            const sectionTopPosition = sectionElement.offsetTop;
            setSectionPosition(sectionTopPosition + 1000);
        }

        window.addEventListener('resize', () => {
            setIsSmallScreen(window.innerWidth < 800);
        });

        window.addEventListener('scroll', () => {
            setScrollPosition(window.scrollY);
            // const scrollPosition = window.scrollY;
            // console.log('sectionPosition', sectionPosition);
            // console.log('scrollPosition', scrollPosition);

            // if (
            //     scrollPosition >= sectionPosition - 400 &&
            //     scrollPosition <= sectionPosition + 400
            // ) {
            //     hideImages();
            // }
        });
    }, []);

    const distanceToSection = Math.abs(scrollPosition - initalScrollPosition);

    // Check if scroll position is within 400px above or below the section
    const isWithinSection = distanceToSection <= 500;

    // console.log('isWithinSection', isWithinSection);

    useEffect(() => {
        if (!isWithinSection) {
            hideImages();
        }
    }, [distanceToSection]);

    const cardsData = [
        {
            title: 'Users:',
            description:
                'Create and manage user <br> profiles, with options for editing',
            img: 'ph_user-thin.png',
            className: 'users',
            card: 'users',
        },
        {
            title: 'Groups:',
            description:
                'Organize users into groups  <br> for easy access management',
            img: 'ph_users-thin.png',
            className: 'groups',
            card: 'groups',
        },
        {
            title: 'Areas:',
            description:
                'Control access points and zones to provide user access as needed',
            img: 'areas.png',
            className: 'areas',
            card: 'areas',
        },
        {
            title: 'Devices:',
            description:
                'Access comprehensive device <br>information and manage settings',
            img: 'clarity_devices-line.png',
            className: 'devices',
            card: 'devices',
        },
        {
            title: 'Access Points:',
            description:
                'Manage and monitor access doors with indications for online status',
            img: 'Group 26601.png',
            className: 'access-points',
            card: 'accessPoints',
        },
    ];

    const ecosystemHoverImages = {
        users: [
            { img: './images/ecosystem/user1.png', orientation: 'vertical', },
            { img: './images/ecosystem/user2.png', orientation: 'horizontal' },
            { img: './images/ecosystem/user3.png', orientation: 'vertical' },
        ],
        devices: [
            { img: './images/ecosystem/dev1.png', orientation: 'horizontal', cssClass : 'devices-left' },
            { img: './images/ecosystem/dev2.png', orientation: 'horizontal',  cssClass : 'devices-middle' },
            { img: './images/ecosystem/dev3.png', orientation: 'horizontal' },
        ],
        groups: [
            { img: './images/ecosystem/group1.png', orientation: 'vertical' },
            { img: './images/ecosystem/group2.png', orientation: 'horizontal' },
            { img: './images/ecosystem/group3.png', orientation: 'vertical' },
        ],
        accessPoints: [
            { img: './images/ecosystem/ap2.png', orientation: 'vertical' },
            { img: './images/ecosystem/ap1.png', orientation: 'horizontal' },
            { img: './images/ecosystem/ap3.png', orientation: 'horizontal' },
        ],
        areas: [
            { img: './images/ecosystem/area1.png', orientation: 'vertical' },
            { img: './images/ecosystem/area2.png', orientation: 'horizontal' },
            { img: './images/ecosystem/area3.png', orientation: 'vertical' },
        ],
    };

    const showPopupImages = (card) => {
        if (isSmallScreen) {
            return;
        }
        setActiveImages(ecosystemHoverImages[card]);
        // document.body.style.overflow = 'hidden';
        setInitialScrollPosition(window.scrollY);
    };

    const hideImages = () => {
        setActiveImages(null);
        setUsersSelected(false);
        // document.body.style.overflow = '';
    };

    return (
        <>
            <section className="ecosystem-section" id="ecosystem-section">
                <h3 className="section-title">Managing Your Ecosystem</h3>
                <p className="description">
                    Shelly's Access Control System allows you to manage your
                    entire ecosystem with ease:
                </p>
                <div className="center-wrapper">
                    <div className="center-img-wrapper">
                        <img
                            src="./images/ecosystem-center-icon.png"
                            alt="icon"
                        />
                    </div>
                    {cardsData.map((e) => (
                        <Card
                            key={e.title}
                            {...e}
                            setActiveImages={showPopupImages}
                            setUsersSelected={setUsersSelected}
                        />
                    ))}
                </div>
                <div>
                    {activeImages &&
                        activeImages.map((e, i) => (
                            <ImageCard
                                src={e.img}
                                orientation={e.orientation}
                                index={i}
                                hideImages={hideImages}
                                usersSelected={usersSelected}
                                cssClass={e.cssClass}
                            />
                        ))}
                </div>
                <div className={activeImages ? 'overlay' : 'overlay hidden'} />
            </section>
        </>
    );
}
