import { splitText } from '../../../utils/functions';
import './AccessControl.scss';

import React from 'react';

export default function AccessControl() {
    const cardsData = [
        {
            title: 'Identifiers',
            description:
                'Users access points using Bluetooth buttons, RFID tokens and mobile devices.',
            img: 'identifiers.png',
        },
        {
            title: 'Group Permissions',
            description:
                'Access control is primarily managed through user group permissions.',
            img: 'Group 1000004734.png',
        },
        {
            title: 'Access Modes',
            description:
                'Easy one click selection from armed, normal, or disarmed modes for various levels of access control.',
            img: 'Group 1000004727.png',
        },
    ];

    return (
        <section className="access-control-section" id='control'>
            <div className="title-section">
                <h3>Streamlined Access Control</h3>
                <p className='description'>Controlling user access has never been easier:</p>
            </div>
            <div className="access-control-cards-wrapper flex">
                {cardsData.map((e) => (
                    <Card key={e.title} {...e} />
                ))}
            </div>
        </section>
    );
}

function Card({ title, description, img }) {
    return (
        <div className="access-control-card">
            <img src={`./images/icons/${img}`} alt="icon" />
            <h5>{title}</h5>
            <p className='description'>{description}</p>
        </div>
    );
}
