export default function FAQ() {
    //in this file are used ul and li elements in order to use the ready styles
    return (
        <div>
            <section>
                <h2>What is Shelly Access Control?</h2>
                <ul>
                    <li>
                        Shelly Access Control is a web-based platform designed
                        to centralize the management of access control systems
                        utilizing controllers from the Shelly family. It offers
                        various capabilities and applications for efficient
                        access control management.
                    </li>
                </ul>
            </section>
            <section>
                <h2>How do I access Shelly Access Control?</h2>
                <ul>
                    <li>
                        To access Shelly Access Control, users need to log on to
                        www.accesscontrol.shelly.com, select an appropriate
                        plan, and complete the registration application. Access
                        details will be provided via email upon processing.
                    </li>
                </ul>
            </section>
            <section>
                <h2>
                    {' '}
                    What controllers are supported by Shelly Access Control?
                </h2>
                <ul>
                    <li>
                        Shelly Access Control supports Shelly relays from
                        Generation 2 or above.
                    </li>
                </ul>
            </section>
            <section>
                <h2> How can I add controllers to the system?</h2>
                <ul>
                    <li>
                        Controllers can be added by users with Technician or
                        Administrator roles via the mobile application. The
                        process involves scanning for devices, selecting the
                        controller, and configuring Wi-Fi network settings.
                    </li>
                </ul>
            </section>
            <section>
                <h2> What are the roles available in Shelly Access Control?</h2>
                <ul>
                    <li>
                        Shelly Access Control offers roles such as User,
                        Technician, and Admin. Each role has specific privileges
                        and access levels within the system, with Admin having
                        the highest level of control.
                    </li>
                </ul>
            </section>
            <section>
                <h2> Can I customize components within the system?</h2>
                <ul>
                    <li>
                        After successful registration, Administrators can
                        customize default components such as groups, zones, and
                        access points according to their preferences. However,
                        default components cannot be deleted, only renamed.
                    </li>
                </ul>
            </section>
            <section>
                <h2>How is access controlled through the system?</h2>
                <ul>
                    <li>
                        Access control is achieved by configuring user groups,
                        zones, and access points. Users are assigned roles and
                        permissions based on their group memberships, and access
                        is authorized using various identifiers like mobile
                        phone, RFID or Bluetooth.
                    </li>
                </ul>
            </section>
            <section>
                <h2>What hardware models are supported?</h2>
                <ul>
                    <li>
                        Shelly Access Control version 1 supports controller
                        models like Shelly Plus 1, Shelly Plus 1PM, and Shelly
                        Plus 2PM. These controllers can be connected to physical
                        buttons, sensors, or wirelessly controlled using Shelly
                        BLU Button.
                    </li>
                </ul>
            </section>
            <section>
                <h2> How can I monitor system events?</h2>
                <ul>
                    <li>
                        The platform provides detailed event monitoring through
                        various logs, including User Activity, System Activity,
                        Alarm Log, Device Log, and Files. Users can access
                        real-time information and download logs for analysis.
                    </li>
                </ul>
            </section>
            <section>
                <h2> What are the different access levels in groups?</h2>
                <ul>
                    <li>
                        Groups grant users access to specific zones and
                        associated access points. Users within a group may have
                        different access levels based on their roles and
                        permissions, with Group Administrators having additional
                        privileges.
                    </li>
                </ul>
            </section>
            <section>
                <h2> What functionalities are available for access points?</h2>
                <ul>
                    <li>
                        Access points represent doors or entry points in the
                        system. Administrators can manage access points, view
                        detailed information, remotely unlock doors, and monitor
                        user activity specific to each point.
                    </li>
                </ul>
            </section>
            <section>
                <h2>How can I reconfigure devices within the system?</h2>
                <ul>
                    <li>
                        Device settings and configurations can be adjusted by
                        administrators from the Devices screen. This includes
                        configuring inputs, outputs, timers, lock control types,
                        and button types for Shelly controllers.
                    </li>
                </ul>
            </section>
            <section>
                <h2>What is the system overview in Shelly Access Control?</h2>
                <ul>
                    <li>
                        The system overview provides definitions of key
                        components such as users, groups. Access points, areas,
                        controllers and door locks. It highlights the role of
                        each component in ensuring proper operation and control
                        of the access control system.
                    </li>
                </ul>
            </section>
        </div>
    );
}
