import React from 'react';
import { useAuth } from '../../authentication/authentication';
import Header, { AuthHeader } from '../Header/Header';
import Footer from '../Footer/Footer';

import './PageWrapper.scss';

export default function withHeaderAndFooter(Component) {
    //TODO add everywhere this HOC
    return (props) => {
        const { token } = useAuth();

        return (
            <div className='page-wrapper'>
                {!!token ? <AuthHeader /> : <Header />}
                <main>
                    <Component {...props} />
                </main>
                <Footer />
            </div>
        );
    };
}
