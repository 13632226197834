import { createContext, useContext } from 'react';

const context = createContext();

export default function SetupDetailsProvider({ children, ...props }) {
    const value = {
        ...props,
    };

    return <context.Provider value={value}>{children}</context.Provider>;
}

export const useSetupDetailsContext = () => useContext(context);
