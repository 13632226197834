import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import { useSetupDetailsContext } from '../contexts/SetupDetailsContext';
import moment from 'moment-js';
import { billingBackendUrl } from '../../../service/urls';

const Row = ({ rowData }) => {
    const formattedDate =
        rowData.date && moment(rowData.date).format('DD.MM.YYYY');
    const { setupData } = useSetupDetailsContext();
    const formattedPrice = rowData.price && rowData.price.toFixed(2);

    const downloadHandler = async () => {
        console.log('setupData', setupData);
        const link = document.createElement('a');
        const url =
            billingBackendUrl +
            `api/client/get-invoice?clientId=${setupData.externalClientId}&orderId=${rowData.orderId}`;
        link.href = url;
        link.setAttribute('download', `invoice-${formattedDate}.pdf`);
        link.setAttribute('target', '_blank')
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Tooltip title="Download invoice">
            <tr onClick={downloadHandler}>
                <td align="center">{formattedDate}</td>
                <td align="center">{formattedPrice}€</td>
                <td align="center">{rowData.documentNumber}</td>
            </tr>
        </Tooltip>
    );
};

export default function PaymentTable() {
    const { paymentsData } = useSetupDetailsContext();

    const row = {
        date: '10.06.2024',
        plan: 'Premium',
    };
    const rows = [];

    for (let i = 0; i < 18; i++) {
        rows.push(row);
    }

    return (
        <Stack gap={'20px'} className="setup-wrapper payment-table">
            <div className="table-wrapper">
                <table>
                    <thead>
                        <th>Paid on (date)</th>
                        <th>Sum</th>
                        <th>Document Number</th>
                    </thead>
                    <tbody>
                        {paymentsData?.map((r, i) => (
                            <Row key={i} rowData={r} />
                        ))}
                    </tbody>
                </table>
            </div>
        </Stack>
    );
}
