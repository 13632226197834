import { Stack } from '@mui/material';
import { useState } from 'react';
import ReactPlayer from 'react-player';
import MyPopover from '../../../components/Popover/Popover';

import './Monitoring.scss';

const Card = ({ heading, description }) => {
    const [popoverOpen, setPopoverOpen] = useState(false);

    return (
        <div
            onMouseEnter={() => setPopoverOpen(true)}
            className="monitoring-card flex"
            onMouseLeave={() => setPopoverOpen(false)}
        >
            <div>
                <img src="./images/icons/solar_login-outline.png" alt="icon" />
                <div className="img-overlay" />
            </div>
            <div>
                <h5>{heading}</h5>
            </div>
            <MyPopover open={popoverOpen} content={description} className='monitoring'/>
        </div>
    );
};

export default function ComprehensiveMonitoring() {
    return (
        <section className="monitoring-section " id="monitoring">
            <div className="title-section">
                <h3>Monitoring</h3>
                <p className="description">
                    The web environment is exclusively accessible to Admin
                    users, who benefit from a host of features, including:
                </p>
            </div>
            <Stack className="monitoring-content" gap={'20px'}>
                <div className="video-wrapper">
                    <ReactPlayer
                        loop={true}
                        width={'100%'}
                        height={'auto'}
                        playing
                        url={'./videos/comprehensive_monitoring.mp4'}
                    />
                </div>
                <Stack className="cards-section" flexDirection={'row'}>
                    <Card
                        heading={'Logs'}
                        description={
                            'View user activity, system logs, alarm logs,device logs, and files with filter options.'
                        }
                    />
                    <Card
                        heading={'Statistics'}
                        description={
                            'Access the latest statistics and history details about the system.'
                        }
                    />
                    <Card
                        heading={'Realtime'}
                        description={
                            'Stay informed by monitoring real-time system and user activities, and receive instant notifications for any updates or important changes.'
                        }
                    />
                </Stack>
            </Stack>
        </section>
    );
}
