import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import './Policies.scss';
import Terms from './Types/Terms';
import Privacy from './Types/Privacy';
import Cookies from './Types/Cookies';
import FAQ from './Types/FAQ';

export default function Policies({ type }) {
    const contentTypes = {
        terms: { component: <Terms />, title: 'Terms and conditions' },
        privacy: { component: <Privacy />, title: 'Privacy Policy' },
        cookie: { component: <Cookies />, title: 'Cookie Policy' },
        faq: { component: <FAQ />, title: 'FAQ' },
    };
    return (
        <>
            <Header />
            <main className="terms-page-main">
                <h1>{contentTypes[type]?.title}</h1>
                <div className="content-wrapper">
                    {contentTypes[type]?.component}
                </div>
            </main>
            <Footer />
        </>
    );
}
