import './Landing.scss';
import HeroSection from './HeroSection/HeroSection';
import Register from './Register/Register';
import GetStarted from './GetStarted/GetStarted';
import TheNewGeneration from './TheNewGeneration/TheNewGeneration';
import RolesAndAccess from './RolesAndAccess/RolesAndAccess';
import KeyFeatures from './KeyFeatures/KeyFeatures';
import Ecosystem from './Ecosystem/Ecosystem';
import AccessControl from './AccessControl/AccessControl';
import DownloadApp from './DownloadApp/DownloadApp';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import RequestPopup from './RequestPopup/RequestPopup';
import ComprehensiveMonitoring from './ComprehensiveMonitoring/Monitoring';

export default function Landing() {
    return (
        <>
            <Header />
            <main className="landing-wrapper">
                <div className='popup-overlay'></div>
                <HeroSection />
                <TheNewGeneration />
                <GetStarted />
                <KeyFeatures />
                <RolesAndAccess />
                <Ecosystem />
                <AccessControl />
                <ComprehensiveMonitoring />
                <DownloadApp />
                <Register />
                <RequestPopup/>
            </main>
            <Footer />
        </>
    );
}
