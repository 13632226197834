import { styled } from '@mui/material/styles';
import { FormControlLabel } from '@mui/material';
import MUISwitch from '@mui/material/Switch';

const AntSwitch = styled(MUISwitch)(({ theme }) => ({
    width: 33,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(17px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor:
                    theme.palette.mode === 'dark' ? '#177ddc' : '#2491ff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: 'rgba(255,255,255,.35)',

        boxSizing: 'border-box',
    },
}));

export default function Switch({ label }) {
    return (
        // <Stack direction="row" spacing={1} alignItems="center">

        //     <AntSwitch
        //         defaultChecked
        //         inputProps={{ 'aria-label': 'ant design' }}
        //     />
        // </Stack>
        <FormControlLabel
            control={<AntSwitch inputProps={{ 'aria-label': 'ant design' }} />}
            label={label}
        />
    );
}
