import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';

export default function ConcentsCheckboxes({registerForm, termsError, setConsentsChecked}) {
    return (
        <>
            <div className="input-wrapper checkbox-input">
                <Checkbox
                    {...registerForm('terms', {
                        onChange: (e, v) => {
                            if(setConsentsChecked) {
                                setConsentsChecked(e.target.checked)
                            }
                        }
                    })}
                    id="terms"
                />
                <label for="terms" className="terms-label">
                    I have read and agreed to the{' '}
                    <Link
                        className="register-popup-link"
                        target="_blank"
                        rel="noreferrer"
                        to='/terms-conditions'
                    >
                        Terms and conditions
                    </Link>
                    {' '} and {' '}
                    <Link
                        className="register-popup-link"
                        target="_blank"
                        rel="noreferrer"
                        to='/privacy-policy'
                    >
                        Privacy policy
                    </Link>
                    {' '}
                    of the Shelly Access Control System. I am aware that my
                    personal data is processed for the purposes of the
                    registration and the use of the Shell Access Control System.
                </label>
                {termsError && <p className="error-msg">you must agree</p>}
            </div>
            <div className="input-wrapper checkbox-input">
                <Checkbox
                    {...registerForm('subscribe')}
                    id="subscribe"
                    defaultChecked
                />
                <label for="subscribe" className="subscribe-label">
                    I want to receive exclusive deals and the latest information
                    about Shelly products and Services.
                </label>
            </div>
        </>
    );
}
