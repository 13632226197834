import { useDispatch } from 'react-redux';
import { openRegisterPopup, setSelectedCard, setSelectedPlan } from '../../../redux/features/landingPageState';
import './KeyFeatures.scss';
import { Link } from 'react-router-dom';
import { ClickAwayListener, Dialog } from '@mui/material';
import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

export default function KeyFeatures() {
    const dispatch = useDispatch();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [video, setVideo] = useState('');

    const cardsData = [
        {
            img: 'Rectangle1.png',
        },
        {
            img: 'Rectangle2.png',
        },
        {
            img: 'Rectangle3.png',
        },
        {
            img: 'Rectangle4.png',
        },
        {
            img: 'Rectangle5.png',
        },
        {
            img: 'Rectangle6.png',
        },
        {
            img: 'Rectangle7.png',
        },
        {
            img: 'Rectangle8.png',
        },
        {
            img: 'Rectangle9.png',
        },
    ];

    const registerButtonHandler = (ev) => {
        ev.stopPropagation();
        dispatch(setSelectedCard('USER'));
        dispatch(setSelectedPlan('USER'));
        setTimeout(() => {
            dispatch(openRegisterPopup());  
        }, 300)
    };

    const closeDialogHandler = () => {
        setDialogOpen(false);
        setVideo('');
    };

    useEffect(() => {
        window.addEventListener('scroll', closeDialogHandler);
    }, []);

    return (
        <section className="key-features-section" id="features">
            <h3 className="section-title">Key Features</h3>
            <div className="cards-wrapper">
                {cardsData.map((e, i) => (
                    <Card
                        key={i}
                        {...e}
                        index={i}
                        showVideo={(path) => {
                            return 
                            setDialogOpen(true);
                            setVideo(path);
                        }}
                    />
                ))}
            </div>
            <div className="key-features-mobile-card">
                <Card img={'Rectangle9.png'} />
            </div>
            <div className="btn-wrapper flex">
                <Link
                    to={'/#get-started'}
                    className="btn-blue"
                    onClick={registerButtonHandler}
                >
                    Register for Free Trial
                </Link>
            </div>
            <div
                className={
                    dialogOpen ? 'white-overlay video-popup' : 'white-overlay video-popup hidden'
                }
            >
                {video && (
                    <ClickAwayListener onClickAway={closeDialogHandler}>
                        <div className="video-wrapper">
                            <ReactPlayer playing url={video} />
                        </div>
                    </ClickAwayListener>
                )}
            </div>
            {/* <Dialog
                maxWidth={'auto'}
                className="popup-white-overlay key-features-popup"
                open={dialogConfig.open}
                onClose={() =>
                    setDialogConfig((oldState) => ({
                        ...oldState,
                        open: false,
                    }))
                }
            >
                <ReactPlayer playing url={dialogConfig.video} />
            </Dialog> */}
        </section>
    );
}

function Card({ img, index, showVideo }) {
    const videoPaths = {
        0: '',
        1: './images/keyFeatures/smart_door_lock.mp4',
        2: '',
        3: '',
        4: '',
        5: '',
        6: '',
        7: '',
        8: '',
        9: '',
    };
    return (
        <div
            className="card"
            onClick={() => {
                if (index !== 1) {
                    return;
                }
                showVideo(videoPaths[index]);
            }}
        >
            <div>
                <img src={`./images/keyFeatures/${img}`} alt="" />
            </div>
        </div>
    );
}
