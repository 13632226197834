import { useSelector } from "react-redux";
import { plansData } from "../utils/common";

const useGetSelectedPlan = () => {
    const selectedPlanType = useSelector(
        (state) => state.landingPageState?.selectedPlan
    );
    return plansData.find((e) => e.planType === selectedPlanType);
}

export default useGetSelectedPlan;