import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import CreateSetupForm from '../../components/CreateSetupForm/CreateSetupForm';
import './AddSetup.scss';

export default function AddSetup() {
    const [separateSetupChecked, setSeparateSetupChecked] = useState(false);
    const [ownDomainChecked, setOwnDomainChecked] = useState(false);
    const {
        register: registerForm,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();


    return (
        <section className="add-setup-section flex">
            <div className='form-wrapper'>
                <CreateSetupForm
                    isRegister={false}
                    separateSetupChecked={separateSetupChecked}
                    ownDomainChecked={ownDomainChecked}
                    registerForm={registerForm}
                    handleSubmit={handleSubmit}
                    setSeparateSetupChecked={setSeparateSetupChecked}
                    errors={errors}
                    setOwnDomainChecked={setOwnDomainChecked}
                    resetForm={reset}
                />
            </div>
        </section>
    );
}
