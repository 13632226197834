import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { closeRegisterPopup } from '../../../redux/features/landingPageState';
import { useForm } from 'react-hook-form';
import CreateSetupForm from '../../../components/CreateSetupForm/CreateSetupForm';
import useGetSelectedPlan from '../../../hooks/useGetSelectedPlan';

import './Register.scss';

export default function Register() {
    const [ownDomainChecked, setOwnDomainChecked] = useState(false);
    const [separateSetupChecked, setSeparateSetupChecked] = useState(false);
    const [hasRegistered, setHasRegistered] = useState(false);
    const [setupName, setSetupName] = useState('');
    const selectedPlan = useGetSelectedPlan();
    const [controllersNum, setControllersNum] = useState(null);

    const {
        register: registerForm,
        handleSubmit,
        formState: { errors },
        reset,
        getValues
    } = useForm({ defaultValues: { subscribe: true } });
    const open = useSelector(
        (state) => state.landingPageState?.registerPopupOpen
    );
    const dispatch = useDispatch();

    function closeHandler() {
        dispatch(closeRegisterPopup());
        reset();
        setOwnDomainChecked(false);
        setSeparateSetupChecked(false);

        setTimeout(() => {
            setHasRegistered(false);
        }, 500);
    }


    return (
        <Dialog className="register-popup" onClose={closeHandler} open={open}>
            <div className="register-popup-wrapper" onClick={(ev) => ev.stopPropagation()}>
                {hasRegistered ? (
                    <RegisteredView
                        setupName={setupName}
                        title={selectedPlan?.title}
                        price={
                            controllersNum && (Number(controllersNum) * selectedPlan.price).toFixed(2)
                        }
                    />
                ) : (
                    <CreateSetupForm
                        closeHandler={closeHandler}
                        separateSetupChecked={separateSetupChecked}
                        ownDomainChecked={ownDomainChecked}
                        registerForm={registerForm}
                        handleSubmit={handleSubmit}
                        setSeparateSetupChecked={setSeparateSetupChecked}
                        errors={errors}
                        setOwnDomainChecked={setOwnDomainChecked}
                        setHasRegistered={setHasRegistered}
                        setSetupName={setSetupName}
                        selectedPlan={selectedPlan}
                        isRegister={true}
                        resetForm={reset}
                        setControllersNum={setControllersNum}
                    />
                )}
            </div>
        </Dialog>
    );
}

function RegisteredView({ title, setupName, price }) {
    return (
        <div className="registered-view">
            <p>
                Plan: {title} {price && `(€${price}/month)`}
            </p>
            <p>Setup name: {setupName}</p>
            <p>
                Please check your email. <br /> You will receive a link. <br />{' '}
                Follow it to complete the account.
            </p>
        </div>
    );
}
