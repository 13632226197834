import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export default function Loader({ height = 0 }) {
    return (
        <div
            style={{
                height,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#474b51'
            }}
        >
                  <CircularProgress color="inherit" />
        </div>
    );
}
