import axios from "axios";
import { useMemo } from "react";
import { useAuth } from "../authentication/authentication";
import { apiBaseUrl, billingBackendUrl } from "../service/urls";

axios.defaults.baseURL = apiBaseUrl;

export const axiosRedirectInstance = axios.create({
    baseURL: billingBackendUrl,
});



export function useAxiosConfig() {
    const authHandler = useAuth();
    // console.log(authHandler)
    // console.log('authHandler?.keycloak', authHandler?.keycloak);

    const requestInterceptor = () => {
        axios.interceptors.request.clear();
        axios.interceptors.request.use((config) => interceptor(config));
        
        function interceptor(config) {
        
            if (!!authHandler.token) {

                config.headers.Authorization = `Bearer ${authHandler?.keycloak?.token}`;
                return config; 
            }

            // console.log('No token', authHandler);

            const abortController = new AbortController();
            abortController.abort();
            config.signal = abortController.signal;

            if (authHandler.isAuthStatusChecked) {
                authHandler.login();
            }

            return config;
        }
    };

    const responseInterceptor = () => {
        const interceptor = () => {
            
        }

        axios.interceptors.response.clear();

        axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                console.error(error);
                if (error.response?.status === 401) {
                    authHandler.login();
                } 
                return Promise.reject(error);
            }
        );
    };

    useMemo(() => {
        requestInterceptor();
        responseInterceptor();
    }, [authHandler]);
}

