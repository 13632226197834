import { useEffect, useState } from 'react';
import { useFeedbackContext } from '../../../contexts/Feedback';
import { updateSetupName } from '../../../service/service';
import { Stack, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import InfoIcon from '@mui/icons-material/Info';
import { useAuth } from '../../../authentication/authentication';
import { useParams } from 'react-router-dom';
import {
    billingRedirect,
    getSetupSubscriptionMessage,
} from '../../../utils/functions';
import { useSetupDetailsContext } from '../contexts/SetupDetailsContext';
import moment from 'moment-js';
import { jwtDecode } from 'jwt-decode';
import { planNames, planTypes, roles } from '../../../utils/common';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { downloadInvoice } from '../../../service/billingService';

export default function NamePaySection({
    name,
    refetch,
    lastInvoice,
    clientId,
}) {
    const [isEdit, setIsEdit] = useState(false);
    const [nameInputValue, setNameInputValue] = useState(name);
    const [isLoading, setIsLoading] = useState(false);
    const { id } = useParams();
    const { updateToken, role } = useAuth();
    const { showFeedback } = useFeedbackContext();
    const { setupData, billingData } = useSetupDetailsContext();
    const waitingForPayment = billingData?.status !== 'ACTIVE';
    const token = localStorage.getItem('token');
    const decoded = token && jwtDecode(token);
    const endDate = new Date(billingData?.endDate);
    const startDate = new Date(billingData?.startDate);

    if (!waitingForPayment) {
        endDate.setMonth(endDate.getMonth() + 1);
        startDate.setMonth(startDate.getMonth() + 1);
    }

    const getPlanType = () => {
        const roles = decoded?.roles;
        if (roles?.includes(planTypes.PREMIUM_USER)) {
            return planTypes.PREMIUM_USER;
        }

        if (roles?.includes(planTypes.CORPORATE)) {
            return planTypes.CORPORATE;
        }

        if (roles?.includes(planTypes.INTEGRATOR)) {
            return planTypes.INTEGRATOR;
        }
    };

    const planType = getPlanType();

    useEffect(() => {
        setNameInputValue(name);
    }, [name]);

    async function saveEditHandler() {
        if (!isEdit) {
            setIsEdit(true);
            return;
        }
        setIsLoading(true);
        try {
            await updateSetupName({ name: nameInputValue }, id);
            await updateToken(-1);
            refetch();
        } catch (err) {
            console.log(err);
            showFeedback(err.response?.data?.message);
        }
        setIsLoading(false);
        setNameInputValue(name);
        setIsEdit(false);
    }

    const saveHandler = async () => {
        if (isLoading) {
            return;
        }

        setIsLoading(true);
        try {
            await updateSetupName({ name: nameInputValue }, id);
            await updateToken(-1);
            refetch();
        } catch (err) {
            console.log(err);
            showFeedback(err.response?.data?.message);
        }
        setIsLoading(false);
        setNameInputValue(name);
        setIsEdit(false);
    };

    return (
        <div>
            {role === roles.USER && (
                <Tooltip title="Your current plan is Free. If you want to use more resources, an upgrade is required. To do that, you should contact our support team.">
                    <InfoIcon   className="hover-pointer"/>
                </Tooltip>
            )}
            {isEdit ? (
                <Stack
                    className="edit-name-input-wrapper"
                    flexDirection={'row'}
                    alignItems={'center'}
                    gap={'5px'}
                >
                    <input
                        value={nameInputValue}
                        onChange={(ev) => setNameInputValue(ev.target.value)}
                        className="input"
                    />
                    <SaveIcon
                        className="hover-pointer icon-grey"
                        onClick={saveHandler}
                    />
                </Stack>
            ) : (
                <Stack
                    flexDirection={'row'}
                    justifyContent={'center'}
                    gap="5px"
                    alignItems={'center'}
                >
                    <h3>{name}</h3>
                    <Tooltip title="Edit name">
                        <EditIcon
                            className="hover-pointer icon-grey"
                            onClick={() => setIsEdit(true)}
                            color="#31ADFF"
                        />
                    </Tooltip>
                </Stack>
            )}
        </div>
    );
}
