import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import './Footer.scss';
import { useFeedbackContext } from '../../contexts/Feedback';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openContactUsPopup } from '../../redux/features/landingPageState';

export default function Footer() {
    const { feedbackConfig, hideFeedback } = useFeedbackContext();
    const dispatch = useDispatch();

    return (
        <footer>
            <div className="width-wrapper flex">
                <div className="logo-section">
                    <img
                        src="./images/shelly 1.png"
                        alt="shelly logo"
                        className="shelly-logo"
                    />
                    <ul className="flex">
                        <a
                            href="https://www.facebook.com/Shelly.IoT/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <li>
                                <img
                                    src="./images/icons/facebook.png"
                                    alt="facebook logo"
                                />
                            </li>
                        </a>
                        <a
                            href="https://twitter.com/shelly.iot/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <li>
                                <img
                                    src="./images/icons/Twitter-X-White-Logo-PNG.png"
                                    alt="twitter logo"
                                />
                            </li>
                        </a>
                        <a
                            href="https://www.instagram.com/shelly_iot/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <li>
                                <img
                                    src="./images/icons/instagram-filled.png"
                                    alt="instagram logo"
                                />
                            </li>
                        </a>
                        <a
                            href="https://www.youtube.com/channel/UCbsZRChOGBmMXx9-6PhCxdw"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <li>
                                <img
                                    src="./images/icons/youtube.png"
                                    alt="youtube logo"
                                />
                            </li>
                        </a>
                    </ul>
                </div>
                <div className="grid-container">
                    <ul>
                        <li>
                            {/* <a>Shelly.com</a> */}
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://www.shelly.com/en-bg"
                            >
                                Shelly.com
                            </a>
                        </li>
                        <li>
                            <a>About Shelly Access Control</a>
                        </li>
                        <li>
                            <a>Successful stories</a>
                        </li>
                        <li onClick={() => dispatch(openContactUsPopup())}>
                            <a>Customer Support</a>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <Link to={'/contacts'}>Contact us</Link>
                        </li>
                        <li>
                            <Link to="/privacy-policy">Privacy Policy</Link>
                        </li>
                        <li>
                            <Link to="/cookies">Cookie Policy</Link>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <Link to="/terms-conditions">
                                Terms and conditions
                            </Link>
                        </li>
                        <li>
                            <Link to={'/faq'}>FAQ</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <Snackbar
                {...feedbackConfig}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={hideFeedback}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />
        </footer>
    );
}
