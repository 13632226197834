import { Fragment } from 'react';
import { planNames } from './common';
import { billingBackendUrl, clientUrl, deployClientUrl } from '../service/urls';
import moment from 'moment-js';
import { getConfigId } from '../service/billingService';

export const splitText = (text) => {
    if (text?.includes('<br>')) {
        const split = text.split('<br>');
        return (
            <>
                {split.map((e, i) =>
                    i !== split.length - 1 ? (
                        <Fragment key={i}>
                            {e}
                            <br />
                        </Fragment>
                    ) : (
                        <Fragment key={i}>{e}</Fragment>
                    )
                )}
            </>
        );
    }
};

export const billingRedirect = async (
    devices,
    externalClientId,
    subscriptionId,
    planType,
    pricePerController,
    setupId,
    setIsLoading
) => {
    const sum = devices * pricePerController;

    if(setIsLoading) {
        setIsLoading(true);
    }
    
    const backUrl = setupId
        ? `${clientUrl}/dashboard/setup/${setupId}`
        : clientUrl;

    const redirectConfig = {
        backendUrl: 'https://bms-billing.infn.dev/backend',
        externalClientId: externalClientId,
        currency: 'EUR',
        fullPrice: parseFloat(sum.toFixed(2)),
        items: [
            {
                itemId: subscriptionId,
                quantity: 1,
                type: 'SUBSCRIPTION',
            },
        ],
        userEmail: '',
        itemName: `${planNames[planType]} - ${devices} controllers`,
        logoUrl: deployClientUrl + '/images/logo.png',
        itemImgUrl: deployClientUrl + '/images/access-control-10.svg',
        redirectOptions: {
            text: 'Successful transaction. Please check you email.',
            successUrl: backUrl,
            backUrl: backUrl,
        },
        cssUrl: deployClientUrl + '/css/billing.css',
        authToken: '',
        withPromoCodes: false,
        withInvoiceData: true,
        withSubscriptions: false,
        itemDescription: `${devices} controllers`,
    };

    try {
        // const encodedConfigId = encodeURIComponent(configId);
        const encodedBackendUrl = encodeURIComponent(
            'https://bms-billing.infn.dev/backend'
        );

        const configId = await getConfigId({
            config: JSON.stringify(redirectConfig),
        });
        const url = `https://pay.infn.dev/#/payment?configId=${configId}&backendUrl=${encodedBackendUrl}`;
        console.log(url);
        window.location = url;
    } catch (err) {
        console.log(err);
    }
};

export const getSetupSubscriptionMessage = (
    status,
    endDate,
    startDate,
    controllers
) => {
    const today = new Date();
    const endDateObj = new Date(endDate);
    const differenceInTime = endDateObj - today;

    const startDateString = startDate && moment(startDate).format('DD.MM.YYYY');
    const endDateString = endDate && moment(endDate).format('DD.MM.YYYY');

    const daysUntil = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));

    switch (status) {
        case 'ACTIVE':
            return (
                <>
                    <p>
                        Your setup is active until{' '}
                        <span className="bold">{endDateString}.</span>
                    </p>
                    <p>
                        Your next due date is{' '}
                        <span className="bold">{startDateString}.</span>
                    </p>
                </>
            );
        case 'WAITING':
            return (
                <>
                    <p>
                        Your setup is active until{' '}
                        <span className="bold">{endDateString}.</span>
                        <p>
                            To ensure uninterrupted access after this date,
                            please make sure to complete your payment before{' '}
                            <span className="bold">{endDateString}.</span>
                        </p>
                    </p>
                </>
            );
        case 'EXPIRED':
            return (
                <p>
                    Your setup has expired. To continue enjoying uninterrupted
                    service, please proceed with the payment for your{' '}
                    {controllers} controllers.
                </p>
            );
        default:
            return '';
    }
};
