import './Popover.scss';

export default function MyPopover({ open, content, className='' }) {
    return (
        <div
            className={open ? `my-popover ${className}` : `my-popover popover-hidden ${className}`}
        >
            {content}
        </div>
    );
}
