import Stack from '@mui/material/Stack';
import { useSetupDetailsContext } from '../contexts/SetupDetailsContext';
import {
    billingRedirect,
    getSetupSubscriptionMessage,
} from '../../../utils/functions';
import { planNames, planTypes } from '../../../utils/common';
import { jwtDecode } from 'jwt-decode';

export default function PaymentDetails() {
    const { billingData, setupData, billingDocuments } =
        useSetupDetailsContext();
    const waitingForPayment = billingData?.status !== 'ACTIVE';
    const endDate = new Date(billingData?.endDate);
    const startDate = new Date(billingData?.startDate);
    const token = localStorage.getItem('token');
    const decoded = token && jwtDecode(token);
    const defaultPaymentData = billingDocuments && billingDocuments[0];

    const getPlanType = () => {
        const roles = decoded?.roles;
        if (roles?.includes(planTypes.PREMIUM_USER)) {
            return planTypes.PREMIUM_USER;
        }

        if (roles?.includes(planTypes.CORPORATE)) {
            return planTypes.CORPORATE;
        }

        if (roles?.includes(planTypes.INTEGRATOR)) {
            return planTypes.INTEGRATOR;
        }
    };

    const planType = getPlanType();

    if (!waitingForPayment) {
        endDate.setMonth(endDate.getMonth() + 1);
        startDate.setMonth(startDate.getMonth() + 1);
    }

    // console.log('billingData', billingData);

    const payHandler = () => {
        billingRedirect(
            setupData?.devices,
            setupData?.externalClientId,
            setupData?.subscriptionId,
            planType,
            billingData?.type?.price,
            setupData.id
        );
    };

    return (
        <Stack
            gap={'10px'}
            className="setup-wrapper payment-details"
            justifyContent={'space-between'}
        >
            <p className="bold"> Plan: {planNames[planType]}</p>
            <section className="grow-1">
                <Stack className="light-grey-section status">
                    <p className="bold">Payment Status:</p>
                    {getSetupSubscriptionMessage(
                        billingData?.status,
                        billingData?.endDate,
                        billingData?.startDate,
                        setupData?.devices
                    )}
                </Stack>
            </section>
            {waitingForPayment && (
                <button className="btn-blue-white" onClick={payHandler}>
                    Pay
                </button>
            )}

            {/* TODO retrieve data from BE*/}
            <div className="light-grey-section grow-1">
                <p className="bold">Invoicing</p>
                {defaultPaymentData ? (
                    <>
                        {/* <p>“TechWave Innovations Inc.”</p> */}
                        <p>{defaultPaymentData.address}</p>
                        <p>
                            {defaultPaymentData.city},{' '}
                            {defaultPaymentData.country}
                        </p>
                        <p>{defaultPaymentData.name}</p>
                        <p>{defaultPaymentData.vat}</p>
                    </>
                ) : (
                    <p>No data</p>
                )}
            </div>
        </Stack>
    );
}
