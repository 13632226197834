import { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { addSetup, register } from '../../service/service';
import { useFeedbackContext } from '../../contexts/Feedback';
import { Stack } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../authentication/authentication';
import ConcentsCheckboxes from '../../pages/Landing/ConcentsCheckboxes/ConcentsCheckboxes';
import { planNames, strings } from '../../utils/common';
import Input from '../../components/Input/Input';

import './CreateSetupForm.scss';
import { useSelector } from 'react-redux';
import { apiBaseUrl, clientUrl, deployClientUrl } from '../../service/urls';
import { billingRedirect } from '../../utils/functions';

export default function CreateSetupForm({
    isRegister,
    separateSetupChecked,
    ownDomainChecked,
    registerForm,
    handleSubmit,
    setSeparateSetupChecked,
    errors,
    setOwnDomainChecked,
    setHasRegistered,
    setSetupName,
    selectedPlan,
    resetForm,
    setControllersNum,
}) {
    const [beErrors, setBeErrors] = useState({});
    const isUserPlanType = selectedPlan?.planType === 'USER';
    const { showFeedback } = useFeedbackContext();
    const [isLoading, setIsLoading] = useState(false);
    const shellyDomainInputDisabled = isUserPlanType || !separateSetupChecked;
    const ownDomainInputDisabled = isUserPlanType || !ownDomainChecked;
    const [consentsChecked, setConsentsChecked] = useState(false);
    const { updateToken } = useAuth();
    const navigate = useNavigate();
    const { role } = useAuth();
    const controllersNumRegex = /^(?:100|[1-9]\d|\d)$/;
    const open = useSelector(
        (state) => state.landingPageState?.registerPopupOpen
    );
    // const values = getValues();
    // console.log('values', values);

    const registrationHandler = async (postData, setupName) => {
        const res = await register(postData);
        setSetupName(setupName);

        if (selectedPlan?.planType === 'PREMIUM_USER') {
            billingRedirect(
                postData.devices,
                res.data?.externalClientId,
                res.data?.subscriptionId,
                selectedPlan.planType,
                0.8,
                null,
                setIsLoading
            );
            // window.location = url;
            // window.location = `https://bms-billing.infn.dev/#/payment?subscriptionId=${res.data?.subscriptionId}&externalClientId=${res.data?.externalClientId}`;
            return;
        }

        setHasRegistered(true);
    };

    async function onSubmit(data) {
        const postData = {
            setupName: data.setupName,
        };

        if (separateSetupChecked) {
            postData.domainType = 'SUBDOMAIN';
            postData.domain = data.shellyDomainInput;
        } else if (ownDomainChecked) {
            postData.domainType = 'OWN_DOMAIN';
            postData.domain = data.ownDomainInput;
        } else {
            postData.domainType = 'NONE';
            postData.domain = '';
        }

        if (data.devices) {
            postData.devices = data.devices;
        }

        if (isRegister) {
            postData.name = data.name;
            postData.family = data.family;
            postData.email = data.email;
            postData.companyName = data.companyName;
            postData.type = selectedPlan?.planType;
        } else {
            postData.userType = role;
        }

        try {
            setIsLoading(true);

            if (isRegister) {
                await registrationHandler(postData, data.setupName);
                return;
            }

            await addSetup(postData);
            await updateToken(-1);
            navigate(-1);
        } catch (err) {
            const errorMsg = err.response?.data?.message;
            console.log(err);
            setIsLoading(false);

            if (errorMsg?.includes('email')) {
                setBeErrors({ email: errorMsg });
                return;
            }

            showFeedback(errorMsg);
        }
    }

    useEffect(() => {
        if (!open) {
            setConsentsChecked(false);
        }
    }, []);

    return (
        <form
            className="flex create-setup-form"
            onSubmit={handleSubmit(onSubmit)}
        >
            {isRegister && (
                <div className="flex form-header">
                    <p className="register-plan-title">
                        Plan: {selectedPlan?.title}
                        {selectedPlan?.price && (
                            <>
                                ({Number(selectedPlan?.price).toFixed(2)}
                                €/month)
                            </>
                        )}
                    </p>
                </div>
            )}
            <div className="input-wrapper flex">
                <label className="label" htmlFor="setupName">
                    Setup name
                </label>
                <input
                    className="input"
                    placeholder="Setup name *"
                    id="setupName"
                    {...registerForm('setupName', { required: true })}
                />
                {errors.setupName && (
                    <p className="error-msg">
                        {strings.REQUIRED_VALIDATION_MESSAGE}
                    </p>
                )}
            </div>

            <WithToolTip isUserPlanType={isUserPlanType}>
                <FormControlLabel
                    control={
                        <Checkbox
                            disabled={isUserPlanType || ownDomainChecked}
                            {...registerForm('separateSetup')}
                            onChange={(ev) => {
                                resetForm({ shellyDomainInput: '' });
                                setSeparateSetupChecked(ev.target.checked);
                            }}
                        />
                    }
                    label={'Separate setup'}
                />
            </WithToolTip>
            <WithToolTip isUserPlanType={isUserPlanType}>
                <div className="input-wrapper flex">
                    <label
                        className={
                            shellyDomainInputDisabled
                                ? 'label-disabled label'
                                : 'label'
                        }
                        htmlFor="domain"
                    >
                        Shelly domain
                    </label>
                    <Stack flexDirection="row" gap="10px" alignItems="end">
                        <input
                            className="input shelly-input"
                            disabled={shellyDomainInputDisabled}
                            placeholder="Domain *"
                            id="domain"
                            {...registerForm('shellyDomainInput', {
                                validate: (v) => {
                                    if (separateSetupChecked && !v) {
                                        return false;
                                    }
                                    return true;
                                },
                            })}
                        />
                        <p>.shelly.com</p>
                    </Stack>
                    {errors.shellyDomainInput && (
                        <p className="error-msg">
                            {strings.REQUIRED_VALIDATION_MESSAGE}
                        </p>
                    )}
                </div>
            </WithToolTip>
            <WithToolTip isUserPlanType={isUserPlanType}>
                <FormControlLabel
                    control={
                        <Checkbox
                            disabled={isUserPlanType || separateSetupChecked}
                            {...registerForm('ownDomain')}
                            onChange={(ev) => {
                                resetForm({ ownDomainInput: '' });
                                setOwnDomainChecked(ev.target.checked);
                            }}
                        />
                    }
                    label="Custom domain"
                />
            </WithToolTip>
            <WithToolTip isUserPlanType={isUserPlanType}>
                <div className="input-wrapper flex">
                    <label
                        className={
                            ownDomainInputDisabled
                                ? 'label-disabled label'
                                : 'label'
                        }
                        htmlFor="own-domain"
                    >
                        Own domain
                    </label>
                    <input
                        className="input"
                        disabled={ownDomainInputDisabled}
                        placeholder="Domain *"
                        id="own-domain"
                        {...registerForm('ownDomainInput', {
                            validate: (v) => {
                                if (ownDomainChecked && !v) {
                                    return false;
                                }
                                return true;
                            },
                        })}
                    />
                    {errors.ownDomainInput && (
                        <p className="error-msg">
                            {strings.REQUIRED_VALIDATION_MESSAGE}
                        </p>
                    )}
                </div>
            </WithToolTip>
            {(selectedPlan?.planType === 'PREMIUM_USER' ||
                role === 'PREMIUM_USER') && (
                <Input
                    label="Number controllers (€0.80/month per controller, maximum 100)"
                    id="devices"
                    registerForm={() =>
                        registerForm('devices', {
                            required: strings.REQUIRED_VALIDATION_MESSAGE,
                            validate: (v) =>
                                controllersNumRegex.test(v) ||
                                'enter a number between 1 and 100',
                            onChange: (e) => {
                                if (!setControllersNum) {
                                    return;
                                }
                                setControllersNum(e.target.value);
                            },
                        })
                    }
                    hasError={errors.devices}
                    errorMsg={errors.devices?.message}
                />
            )}
            {isRegister && (
                <>
                    <RegistrationFields
                        errors={errors}
                        registerForm={registerForm}
                        setBeErrors={setBeErrors}
                        beErrors={beErrors}
                    />

                    <ConcentsCheckboxes
                        registerForm={registerForm}
                        termsError={errors.terms}
                        setConsentsChecked={setConsentsChecked}
                    />
                </>
            )}

            <button
                disabled={isLoading || (isRegister && !consentsChecked)}
                className="btn-blue"
                type="submit"
            >
                Create setup
            </button>
        </form>
    );
}

function RegistrationFields({ errors, registerForm, setBeErrors, beErrors }) {
    return (
        <>
            <div className="input-wrapper flex">
                <label className="label" htmlFor="companyName">
                    Company name
                </label>
                <input
                    className="input"
                    placeholder="Company name *"
                    id="companyName"
                    {...registerForm('companyName', { required: true })}
                />
                {errors.companyName && (
                    <p className="error-msg">
                        {strings.REQUIRED_VALIDATION_MESSAGE}
                    </p>
                )}
            </div>
            <div className="input-wrapper flex">
                <label className="label" htmlFor="name">
                    Name
                </label>
                <input
                    className="input"
                    placeholder="Name *"
                    id="name"
                    {...registerForm('name', { required: true })}
                />
                {errors.name && (
                    <p className="error-msg">
                        {strings.REQUIRED_VALIDATION_MESSAGE}
                    </p>
                )}
            </div>
            <div className="input-wrapper flex">
                <label className="label" htmlFor="family">
                    Family
                </label>
                <input
                    className="input"
                    placeholder="Family *"
                    id="family"
                    {...registerForm('family', { required: true })}
                />
                {errors.family && (
                    <p className="error-msg">
                        {strings.REQUIRED_VALIDATION_MESSAGE}
                    </p>
                )}
            </div>
            <div className="input-wrapper flex">
                <label className="label" htmlFor="email-input">
                    Email (we will send an activation link)
                </label>
                <input
                    className="input"
                    id="email-input"
                    {...registerForm('email', {
                        required: true,
                        onChange: () =>
                            setBeErrors((oldState) => ({
                                ...oldState,
                                email: false,
                            })),
                    })}
                    placeholder="Email *"
                />
                {errors.email && (
                    <p className="error-msg">
                        {strings.REQUIRED_VALIDATION_MESSAGE}
                    </p>
                )}
                {!!beErrors?.email && (
                    <p className="error-msg">{beErrors?.email}</p>
                )}
            </div>
        </>
    );
}

function WithToolTip({ children, isUserPlanType }) {
    return (
        <>
            {isUserPlanType ? (
                <Tooltip title="Available with higher plan">{children}</Tooltip>
            ) : (
                children
            )}
        </>
    );
}
