import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import Header, { AuthHeader } from '../../components/Header/Header';
import { AuthProvider, useAuth } from '../../authentication/authentication';
import Dashboard from '../../pages/Dashboard/Dashboard';
import { useAxiosConfig } from '../../axios/config';

import './Authenticated.scss';
import { getTenants, redirect } from '../../service/service';
import Loader from '../../components/Loader/Loader';
import SetupDetails from '../../pages/SetupDetails/SetupDetails';
import BmsTenants from './BmsTenants';
import AddSetup from '../../pages/AddSetup/AddSetup';
import NotFound from '../../pages/NotFound/NotFound';
import { useFeedbackContext } from '../../contexts/Feedback';
import { keycloakConfig } from '../../utils/configs';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserData, setUser } from '../../redux/features/userData';
import { logout } from '../../authentication/utils';
import { bmsTenantsRedirect } from '../../utils/bmsTenantRedirect';

function getAuthSettings() {
    const settings = localStorage.getItem('authSettings');
    const parsedSettings = settings ? JSON.parse(settings) : null;

    // console.log('settings', settings);

    // return { settings: keycloakConfig, authType: "KEYCLOAK" };
    return parsedSettings;
}

export default function Authenticate() {
    const s = getAuthSettings();

    const navigate = useNavigate();

    useEffect(() => {
        if (!s) {
            navigate('/login');
        }
    }, [s]);

    if (!s) {
        return;
    }

    const { authType, settings } = s;

    return (
        <AuthProvider authType={authType} settings={settings}>
            <Content />
        </AuthProvider>
    );
}

function Content() {
    const { isAuthStatusChecked, token } = useAuth();
    const { role } = useAuth();
    const dispatch = useDispatch();
    const userData = useSelector(selectUserData);
    const { showFeedback } = useFeedbackContext();

    useAxiosConfig();

    if (token && !userData) {
        getUserData();
    }

    useEffect(() => {
        if (userData && !userData?.orchestratorUser) {
            if (userData.tenants?.length === 1) {
                const url = userData.tenants[0].url;

                bmsTenantsRedirect(url);
                return;
            }
        }
    }, [userData]);

    async function getUserData() {
        try {
            const data = await redirect(token);

            dispatch(setUser(data));
        } catch (err) {
            logout();
            console.log('err', err);
            showFeedback();
        }
    }

    if (!userData) {
        return <Loader height={'100vh'} />;
    }

    if (!isAuthStatusChecked || !userData || userData.tenants?.length === 1) {
        return null;
    }

    return (
        <div className="page-wrapper">
            <AuthHeader showNav={true} />
            <div className="auth">
                <Routes>
                    <Route
                        path="/"
                        element={
                            userData?.orchestratorUser ? (
                                <Dashboard />
                            ) : (
                                <BmsTenants tenants={userData?.tenants} />
                            )
                        }
                    />
                    <Route path="/setup/:id" element={<SetupDetails />} />
                    {role !== 'USER' && (
                        <Route path="/add-setup" element={<AddSetup />} />
                    )}
                    <Route path="/*" element={<NotFound />} />
                </Routes>
            </div>
            <Footer />
        </div>
    );
}
