import './TheNewGeneration.scss';

export default function TheNewGeneration() {
    const btnHandler = () => {
        var url = '';

        window.open(url, '_blank');
    };
    return (
        <>
            <section className="the-future-section-wrapper">
            <div id="about"></div>
            {/* <div id="about" style={{marginBottom: '70px'}}></div> */}
                <section className="content-wrapper">
                    <div>
                        <h3 className="section-title">
                            The New Generation <br /> of Access Control
                        </h3>
                        <p>
                            Welcome to Shelly's Access Control System, where
                            centralized access management becomes a reality with
                            Shelly controllers. Our web-based platform offers a
                            wide range of functionalities and applications,
                            empowering users to create, monitor, and maintain a
                            comprehensive ecosystem.
                        </p>
                        <p>
                            Our system simplifies the management of Shelly
                            controller-based systems, offering seamless
                            integration and accessibility, even without an
                            internet connection. This is achieved through a list
                            of identifiers that operate autonomously.
                        </p>
                    </div>
                    <div className="btn-wrapper">
                        <button className="btn-blue">How it works</button>
                    </div>
                </section>
            </section>
        </>
    );
}
