import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';

import { useDispatch, useSelector } from 'react-redux';
import {
    closeAddRemoveDomainsDialog,
    selectAddRemoveDomainsDialogOpen,
} from '../../../redux/features/setupDetailsState';
import { useQuery } from 'react-query';
import {
    deleteAuthConfigDomain,
    getAuthConfigDomains,
    postAuthConfigDomain,
} from '../../../service/service';
import {
    domainRegex,
    reactQuerySettings,
    strings,
} from '../../../utils/common';
import Input from '../../../components/Input/Input';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useFeedbackContext } from '../../../contexts/Feedback';

const Domain = ({ domainData, authConfig, refetch, shouldDelete }) => {
    const { showFeedback } = useFeedbackContext();

    const removeDomainHandler = async () => {
        if(!shouldDelete) {
            return;
        }
        try {
            await deleteAuthConfigDomain(authConfig.id, domainData.id);
            refetch();
        } catch (err) {
            showFeedback(err.response?.data?.message);
        }
    };

    return (
        <Stack flexDirection={'row'} gap={'10px'} alignItems={'baseline'}>
            <div className="domain-name">{domainData.domain}</div>
            <div>
                <IconButton
                    className="add-remove-domain-btn"
                    onClick={removeDomainHandler}
                >
                    <RemoveIcon />
                </IconButton>
            </div>
        </Stack>
    );
};

export default function AddRemoveDomains({ authConfig }) {
    const open = useSelector(selectAddRemoveDomainsDialogOpen);
    const dispatch = useDispatch();
    const { showFeedback } = useFeedbackContext();

    const {
        data: domainsData,
        refetch,
        error,
    } = useQuery(
        'domains-data',
        () => getAuthConfigDomains(authConfig.id),
        reactQuerySettings
    );

    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm();

    useEffect(() => {
        if (error) {
            showFeedback(error.response?.data?.message);
        }
    }, [error]);

    const closeHandler = () => {
        dispatch(closeAddRemoveDomainsDialog());
        reset();
    };

    const submitHandler = async (data) => {
        console.log('data', data);
        try {
            await postAuthConfigDomain(authConfig.id, data);
            refetch();
            reset();
        } catch (err) {
            showFeedback(err.response?.data?.message);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={closeHandler}
            className="add-remove-domain-popup"
        >
            <div className="popup-content-wrapper">
                <Stack flexDirection={'row'} gap={'20px'} alignItems={'center'}>
                    <DialogTitle>Add/Remove Domains</DialogTitle>
                    <div>
                        <IconButton onClick={closeHandler}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Stack>
                <Stack gap={'20px'}>
                    <form onSubmit={handleSubmit(submitHandler)}>
                        <label for={'domain'}>New domain name:</label>
                        <Stack
                            flexDirection={'row'}
                            alignItems={'end'}
                            gap="10px"
                        >
                            <Input
                                inputWrapperClassName="grow-1"
                                // label="New domain name"
                                id={'domain'}
                                placeholder={'Name'}
                                registerForm={() =>
                                    register('domain', {
                                        required:
                                            strings.REQUIRED_VALIDATION_MESSAGE,
                                        pattern: {
                                            value: domainRegex,
                                            message: strings.VALID_DOMAIN,
                                        },
                                    })
                                }
                                hasError={errors?.domain}
                                errorMsg={errors?.domain?.message}
                            />
                            <div>
                                <IconButton
                                    className="add-remove-domain-btn"
                                    type="submit"
                                >
                                    <AddIcon />
                                </IconButton>
                            </div>
                        </Stack>
                    </form>
                    <Stack gap={'10px'}>
                        <p>Added domains:</p>
                        <Stack gap={'10px'}>
                            {domainsData?.map((e) => (
                                <Domain
                                    key={e.id}
                                    domainData={e}
                                    authConfig={authConfig}
                                    refetch={refetch}
                                    shouldDelete={domainsData.length > 1}
                                />
                            ))}
                        </Stack>
                    </Stack>
                </Stack>
            </div>
        </Dialog>
    );
}
