import './axios/config';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { store } from './redux/store/store';

import FeedbackContextProvider from './contexts/Feedback';
import ScrollToHashElement from './components/ScrollTo/ScrollTo';
import Authenticate from './views/Authenticated/Authenticated';
import Landing from './pages/Landing/Landing';
import Policies from './components/Policies/Policies';
import NotFound from './pages/NotFound/NotFound';

import './App.scss';
import Login from './pages/Login/Login';
import Contacts from './pages/Contacts/Contacts';
import ContactUs from './pages/Landing/ContactUs/ContactUs';
import FAQ from './components/Policies/Types/FAQ';

function App() {
    const queryClient = new QueryClient();

    return (
        <HashRouter>
            <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                    <FeedbackContextProvider>
                        <div className="page-wrapper" id='/'>
                            <Routes>
                                <Route path="/" element={<Landing />} />
                                <Route
                                    path="/terms-conditions"
                                    element={<Policies type="terms" />}
                                />
                                <Route
                                    path="/privacy-policy"
                                    element={<Policies type="privacy" />}
                                />
                                <Route
                                    path="/cookies"
                                    element={<Policies type="cookie" />}
                                />
                                <Route
                                    path="/dashboard/*"
                                    element={<Authenticate />}
                                />
                                <Route path="/login" element={<Login />} />
                                <Route
                                    path="/contacts"
                                    element={<Contacts />}
                                />
                                <Route
                                    path='/faq'
                                    element={<Policies type={'faq'}/>}
                                />
                                <Route
                                    path="/*"
                                    element={
                                        <NotFound withHeaderAndFooter={true} />
                                    }
                                />
                            </Routes>
                        </div>
                        <ScrollToHashElement />
                        <ContactUs />
                    </FeedbackContextProvider>
                </QueryClientProvider>
            </Provider>
        </HashRouter>
    );
}

export default App;
