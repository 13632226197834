import axios from 'axios';
import { apiBaseUrl, endpoints } from './urls';

const axiosRedirectInstance = axios.create({
    baseURL: apiBaseUrl,
  });

export const register = (data) => {
    return axios.post(endpoints.REGISTER, data);
};

export const addSetup = (data) => {
    return axios.post(endpoints.ADD_TENANT, data);
};

export const redirect = async (token) => {

    const res = await axiosRedirectInstance.get(endpoints.REDIRECT, {headers: {
        'x-auth-token': token
        // 'Authorization': `Bearer ${token}`
    }});
    return res.data;
};

export const getTenants = async () => {
    const res = await axios.get(endpoints.GET_TENANTS);
    return res.data;
};

export const getSetupById = async (id) => {
    const res = await axios.get(`${endpoints.GET_SETUP_DETAILS}/${id}`);
    return res.data;
};

export const updateSetupName = (data, tenantId) => {
    return axios.put(
        `${endpoints.UPDATE_SETTING_NAME}/${tenantId}/rename`,
        data
    );
};

export const deactivateSetup = (id) => {
    return axios.put(`${endpoints.DEACTIVATE_SETUP}/${id}/deactivate`);
};

export const sendRequestEmail = (data) => {
    return axios.post(endpoints.SEND_REQUEST_EMAIL, data);
}

export const updateAuthSettings = (tenantId,data) => {
    return axios.post(endpoints.UPDATE_AUTH_SETTINGS(tenantId), data);
}

export const getAuthConfigDomains = async (id) => {
    const res = await axios.get(endpoints.GET_AUTH_CONFIG_DOMAINS(id));
    return res.data;
};

export const postAuthConfigDomain = (authConfigId, data) => {
    return axios.post(endpoints.ADD_AUTH_CONFIG_DOMAIN(authConfigId), data);
}

export const deleteAuthConfigDomain = (authConfigId, emailDomainId) => {
    return axios.delete(endpoints.DELETE_AUTH_CONFIG_DOMAIN(authConfigId, emailDomainId));
}

export const checkEmailAuthServers = async (data) => {
    const res = await axios.post(endpoints.CHECK_EMAIL_AUTH_SERVERS, data);
    return res.data;
}
