//TODO Check plaType when backend is ready

export const plansData = [
    {
        title: 'Free',
        options: [
            '1 area',
            'up to 5 controllers',
            'up to 10 readers',
            'up to 50 users in 1 group',
            'up to 3 identifications per user - one of each type',
        ],
        price: '0',
        planType: 'USER',
        button: {
            type: 'plan',
            label: 'Choose this Plan',
        },
        tenants: 'one tenant',
    },
    {
        title: 'Premium',
        options: [
            'up to 10 areas',
            'up to 100 controllers',
            'up to 200 readers',
            '+ 50 users in 10 groups, for each controller',
            'personalisation of web address',
        ],
        price: 0.8,
        planType: 'PREMIUM_USER',
        subTitle: 'for each controller / per month',
        button: {
            type: 'plan',
            label: 'Choose this Plan',
        },
        tenants: 'one tenant',
    },
    {
        title: 'Integrator',
        options: [
            'individual conditions',
            'manage one or multiple clients',
            'unlimited environments',
            'include new or existing clients',
            'unlimited system components',
        ],
        price: null,
        planType: 'INTEGRATOR',
        button: {
            type: 'offer',
            label: 'Make a Request',
        },
        tenants: 'multiple tenant',
    },
    {
        title: 'Corporate',
        options: [
            'unlimited areas',
            'unlimited groups',
            '1000 controllers',
            '2000 readers',
            '10000 users with 10 identifications per user',
            'separate installation and domain',
        ],
        price: null,
        planType: 'CORPORATE',
        button: {
            type: 'offer',
            label: 'Make a Request',
        },
        tenants: 'multiple tenant',
    },
];

export const reactQuerySettings = {
    retry: 2,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
};

export const planRoles = ['USER', 'PREMIUM_USER', 'INTEGRATOR', 'CORPORATE'];

export const roles = {
    USER: 'USER',
    PREMIUM_USER: 'PREMIUM_USER',
    INTEGRATOR: 'INTEGRATOR',
    CORPORATE: 'CORPORATE',
};

export const marketingCookies = [
    {
        name: '_fbp',
        provider: 'cookiebot.com',
        purpose:
            'Used by Facebook to deliver a series of advertisement products such as real time bidding from third party advertisers.',
        expiry: '3 months',
        type: 'HTTP Cookie',
    },
    {
        name: 'fr',
        provider: 'facebook.com',
        purpose:
            'Used by Facebook to deliver a series of advertisement products such as real time bidding from third party advertisers.',
        expiry: '3 months',
        type: 'HTTP Cookie',
    },
    {
        name: 'GPS',
        provider: 'youtube.com',
        purpose:
            'Registers a unique ID on mobile devices to enable tracking based on geographical GPS location.',
        expiry: '1 day',
        type: 'HTTP Cookie',
    },
    {
        name: 'IDE',
        provider: 'doubleclick.net',
        purpose:
            "Used by Google DoubleClick to register and report the website user's actions after viewing or clicking one of the advertiser's ads with the purpose of measuring the efficacy of an ad and to present targeted ads to the user.",
        expiry: '1 year',
        type: 'HTTP Cookie',
    },
    {
        name: 'pagead/1p-user-list/#',
        provider: 'google.com',
        purpose: 'Unclassified',
        expiry: 'Session',
        type: 'Pixel Tracker',
    },
    {
        name: 'PREF',
        provider: 'youtube.com',
        purpose:
            'Registers a unique ID that is used by Google to keep statistics of how the visitor uses YouTube videos across different websites.',
        expiry: '8 months',
        type: 'HTTP Cookie',
    },
    {
        name: 'test_cookie',
        provider: 'doubleclick.net',
        purpose: "Used to check if the user's browser supports cookies.",
        expiry: '1 day',
        type: 'HTTP Cookie',
    },
    {
        name: 'tr',
        provider: 'facebook.com',
        purpose:
            'Used by Facebook to deliver a series of advertisement products such as real time bidding from third party advertisers.',
        expiry: 'Session',
        type: 'Pixel Tracker',
    },
    {
        name: 'VISITOR_INFO1_LIVE',
        provider: 'youtube.com',
        purpose:
            "Tries to estimate the users' bandwidth on pages with integrated YouTube videos.",
        expiry: '179 days',
        type: 'HTTP Cookie',
    },
    {
        name: 'YSC',
        provider: 'youtube.com',
        purpose:
            'Registers a unique ID to keep statistics of what videos from YouTube the user has seen.',
        expiry: 'Session',
        type: 'HTTP Cookie',
    },
    {
        name: 'yt-remote-cast-installed',
        provider: 'youtube.com',
        purpose:
            "Stores the user's video player preferences using embedded YouTube video",
        expiry: 'Session',
        type: 'HTML Local Storage',
    },
    {
        name: 'yt-remote-connected-devices',
        provider: 'youtube.com',
        purpose:
            "Stores the user's video player preferences using embedded YouTube video",
        expiry: 'Persistent',
        type: 'HTML Local Storage',
    },
    {
        name: 'yt-remote-device-id',
        provider: 'youtube.com',
        purpose:
            "Stores the user's video player preferences using embedded YouTube video",
        expiry: 'Persistent',
        type: 'HTML Local Storage',
    },
    {
        name: 'yt-remote-session-app',
        provider: 'youtube.com',
        purpose:
            "Stores the user's video player preferences using embedded YouTube video",
        expiry: 'Session',
        type: 'HTML Local Storage',
    },
    {
        name: 'yt-remote-session-name',
        provider: 'youtube.com',
        purpose:
            "Stores the user's video player preferences using embedded YouTube video",
        expiry: 'Session',
        type: 'HTML Local Storage',
    },
    {
        name: '_pk_ref',
        provider: 'matomo.org',
        purpose:
            'Used to store the attribution information, the referrer initially used to visit the website.',
        expiry: '6 months',
        type: 'HTTP Cookie',
    },
    {
        name: '_pk_cvar',
        provider: 'matomo.org',
        purpose: 'Used to temporarily store data for the visit.',
        expiry: '30 minutes',
        type: 'HTTP Cookie',
    },
    {
        name: '_pk_id',
        provider: 'matomo.org',
        purpose:
            'Used to store a few details about the user such as the unique visitor ID). The purpose is statistic (anonymous).',
        expiry: '13 months',
        type: 'HTTP Cookie',
    },
    {
        name: '_pk_ses',
        provider: 'matomo.org',
        purpose:
            'Used to temporarily store data for the visit such as a unique session ID. The purpose is statistic (anonymous), browsing behavior.',
        expiry: '30 minutes',
        type: 'HTTP Cookie',
    },
    {
        name: 'matomo_sessid',
        provider: 'matomo.org',
        purpose:
            'It is important to note that it doesn’t contain any data used to identify visitors and is considered an “Essential” cookie. The purpose is functional. Used to provide fraud prevention, helps prevents CSRF security issues.',
        expiry: '14 days',
        type: 'HTTP Cookie',
    },
    {
        name: 'matomo_lang',
        provider: 'matomo.org',
        purpose:
            'It is language manager cookie. The purpose is statistic (anonymous), browsing behavior.',
        expiry: '14 days',
        type: 'HTTP Cookie',
    },
];

export const statisticCookies = [
    {
        name: '_ga',
        provider: 'cookiebot.com',
        purpose:
            'Registers a unique ID that is used to generate statistical data on how the visitor uses the website.',
        expiry: '2 years',
        type: 'HTTP Cookie',
    },
    {
        name: '_gid',
        provider: 'cookiebot.com',
        purpose:
            'Registers a unique ID that is used to generate statistical data on how the visitor uses the website',
        expiry: '1 day',
        type: 'HTTP Cookie',
    },
    {
        name: 'collect',
        provider: 'google-analytics.com',
        purpose:
            "Used to send data to Google Analytics about the visitor's device and behavior. Tracks the visitor across devices and marketing channels.",
        expiry: 'Session',
        type: 'Pixel Tracker',
    },
    {
        name: 'ZD-buid',
        provider: 'cookiebot.com',
        purpose: 'Unique id that identifies the user on recurring visits.',
        expiry: 'Persistent',
        type: 'HTML Local Storage',
    },
    {
        name: 'ZD-currentTime',
        provider: 'cookiebot.com',
        purpose:
            "Registers the date and time for the user's latest visit to the website",
        expiry: 'Session',
        type: 'HTML Local Storage',
    },
];

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const domainRegex =
    /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;

export const strings = {
    REQUIRED_VALIDATION_MESSAGE: 'field is required',
    VALID_EMAIL: 'please enter a valid email',
    AUTH_SETTING_CHANGE_SUCCESS: 'Settings successfully changed',
    VALID_DOMAIN: 'please enter a valid domain',
    MAX_SYMBOLS_20: 'maximum 20 symbols allowed',
    MAX_SYMBOLS_300: 'maximum 300 symbols allowed',
};

export const planTypes = {
    PREMIUM_USER: 'PREMIUM_USER',
    INTEGRATOR: 'INTEGRATOR',
    CORPORATE: 'CORPORATE',
    USER: 'USER',
};

export const planNames = {
    PREMIUM_USER: 'Premium',
    INTEGRATOR: 'Integrator',
    CORPORATE: 'Corporate',
};

export const billingStatuses = {
    WAITING: 'WAITING',
    ACTIVE: 'ACTIVE',
    EXPIRED: 'EXPIRED',
};
