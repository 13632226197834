import { Link } from 'react-router-dom';
import './HeroSection.scss';

export default function HeroSection() {
    return (
        <section className="hero-section">
            <div className="hero-content">
                <section className="title-section">
                    <h1>
                        Welcome to <br /> Shelly Access Control System
                    </h1>
                    <p className="sub-title">Centralized Control and Beyond</p>
                </section>
                <div className='buttons-wrapper'>
                    <Link to="/#get-started" className='btn-black'>Register</Link>
                    <Link to={'/login'} className='btn-blue'>Login</Link>
                </div>
                <div className='devices-img-section'>
                    <img src="./images/hero-devices.png" alt="devices" />
                </div>

            </div>
        </section>
    );
}
