import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../authentication/authentication';
import { clientUrl } from '../../service/urls';
import { useState } from 'react';
import './Header.scss';
import { logout } from '../../authentication/utils';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserData } from '../../redux/features/userData';
import { useEffect } from 'react';
import { setSelectedCard } from '../../redux/features/landingPageState';

export default function Header() {
    const [showToggleMenu, setShowToggleMenu] = useState(false);
    const [showNav, setShowNav] = useState(true);
    const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
    const dispatch = useDispatch();

    const closeToggleMenu = () => setShowToggleMenu(false);

    const getStartedHandler = () => {
        closeToggleMenu();
        dispatch(setSelectedCard('USER'));
    };

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.scrollY;
            const isVisible = prevScrollPos > currentScrollPos;

            setPrevScrollPos(currentScrollPos);

            //   if(!isVisible && window.scrollY < 220) {
            //     return;
            //   }
            setShowNav(isVisible);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);

    return (
        <>
            <div className="toggle-menu-wrapper">
                <div>
                    <ul
                        className={
                            showToggleMenu
                                ? 'toggle-menu visible'
                                : 'toggle-menu'
                        }
                    >
                        <li className="header-menu-item">
                            <Link
                                className="header-menu-link"
                                to="/#about"
                                onClick={closeToggleMenu}
                            >
                                Home
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="header-menu-link"
                                to="/#get-started"
                                onClick={getStartedHandler}
                            >
                                Get Started
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="header-menu-link"
                                to="/#features"
                                onClick={closeToggleMenu}
                            >
                                Features
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="header-menu-link"
                                to="/#access"
                                onClick={closeToggleMenu}
                            >
                                Access
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="header-menu-link"
                                to="/#managing"
                                onClick={closeToggleMenu}
                            >
                                Managing
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="header-menu-link"
                                to="/#control"
                                onClick={closeToggleMenu}
                            >
                                Control
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="header-menu-link"
                                to="/#monitoring"
                                onClick={closeToggleMenu}
                            >
                                Monitoring
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="header-menu-link"
                                to="/#get-the-app"
                                onClick={closeToggleMenu}
                            >
                                Get the app
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <header className={showNav ? 'flex' : 'flex nav-hidden'}>
                <Link
                    to={'/#/'}
                >
                    <div className="header-img-wrapper">
                        <img src="./images/adminLogoLeft.svg" alt="logo" />
                        <img src="./images/logo.png" alt="logo" />
                    </div>
                </Link>
                <ul className="flex large-screen-menu">
                    <li>
                        <Link className="header-menu-link" to="/#about">
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={(ev) =>{
                                ev.stopPropagation();
                                dispatch(setSelectedCard('USER'))}}
                            className="header-menu-link"
                            to="/#get-started"
                        >
                            Get Started
                        </Link>
                    </li>
                    <li>
                        <Link className="header-menu-link" to="/#features">
                            Features
                        </Link>
                    </li>
                    <li>
                        <Link className="header-menu-link" to="/#access">
                            Access
                        </Link>
                    </li>
                    <li>
                        <Link className="header-menu-link" to="/#managing">
                            Managing
                        </Link>
                    </li>
                    <li>
                        <Link className="header-menu-link" to="/#control">
                            Control
                        </Link>
                    </li>
                    <li>
                        <Link className="header-menu-link" to="/#monitoring">
                            Monitoring
                        </Link>
                    </li>
                    <li>
                        <Link className="header-menu-link" to="/#get-the-app">
                            Get the app
                        </Link>
                    </li>
                </ul>

                <div className="header-buttons-wrapper flex">
                    <div>
                        <Link
                            className="login-btn btn-login"
                            // onClick={() => navigate('/login')}
                            to={'/login'}
                        >
                            Login
                        </Link>
                    </div>
                    <div>
                        <Link
                            to="/#get-started"
                            className="register-btn btn-blue"
                        >
                            Register
                        </Link>
                    </div>
                </div>
                <div
                    className={
                        showToggleMenu
                            ? 'hamburger-menu open'
                            : 'hamburger-menu'
                    }
                    onClick={() => setShowToggleMenu(!showToggleMenu)}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </header>
        </>
    );
}

export const AuthHeader = () => {
    const userData = useSelector(selectUserData);
    const showNav = userData?.orchestratorUser;

    const logoutHandler = () => {

        //for now we don't clear the auth settings
        // localStorage.removeItem('authSettings');
        logout();
    };

    return (
        <header className={'flex auth-header'}>
            <div>
                <div className="header-img-wrapper">
                    <img src="./images/logo.png" alt="logo" />
                </div>
                {showNav && (
                    <ul>
                        <Link to={'/dashboard'}>
                            <li>My setups</li>
                        </Link>
                    </ul>
                )}
            </div>

            <div>
                <button
                    className="logout-btn btn-blue"
                    onClick={logoutHandler}
                >
                    Logout
                </button>
            </div>
        </header>
    );
};
